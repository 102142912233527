<template>
	<div class="fyi-input">
		<label v-if="label">{{label}}</label>
		<input
			class="input-text"
			type="text"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
			@keypress="isNumber($event)"
			v-on:blur="$emit('blur')"
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
		/>
	</div>
</template>

<script>
export default {
    name: "fyi-price",
    props: {
        value: [String, Number],
        alias: String,
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
		decimal: {
			type: Boolean,
			default: true
		}
    },
	beforeMount() {
	},
	methods: {
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if(evt.target.value.includes(".") && charCode === 46) {
				evt.preventDefault();
				return null;
			}
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		}

  	}
};
</script>

<style lang="scss" scoped>
//@import "@/styles/components/form/input";
</style>