import Clients from "../../libs/api/clients";

export default {
    state: {
		seenTrialResponse: sessionStorage.getItem("seenTrialResponse") ? JSON.parse(sessionStorage.getItem("seenTrialResponse")) :  {timestamp: new Date("1990-01-01"), response:false},
        remainingTrialDays: sessionStorage.getItem("remainingTrialDays") || null,
		headerDomain: sessionStorage.getItem("headerDomain") || null,
	},

    getters: {
		seenTrialResponse: (state) => state.seenTrialResponse,
		remainingTrialDays: (state) => state.remainingTrialDays,
		headerDomain: (state) => state.headerDomain
	},

    mutations: {
		setTrialResponse: (state, seenTrialResponse) => {
            state.seenTrialResponse = {timestamp: new Date(), response:seenTrialResponse};
            sessionStorage.setItem("seenTrialResponse", JSON.stringify(state.seenTrialResponse));
        },
		
        setRemainingTrialDays: (state, remainingTrialDays) => {
            state.remainingTrialDays = remainingTrialDays;
            sessionStorage.setItem("remainingTrialDays", remainingTrialDays);
        },
		
		setHeaderDomain: (state, domain) => {
            state.headerDomain = domain;
            sessionStorage.setItem("headerDomain", state.headerDomain);
        },
	},

    actions: {
		isTrial: async ({ commit }) => {
			const response = await Clients.isTrial();
			if(response.end) {
				let date = new Date(response.end);
				let now = new Date();
				let diff = Math.abs(now - date);
				let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
				commit("setRemainingTrialDays", diffDays);
			} else {
				commit("setRemainingTrialDays", 1);
			}
            return response;
        },
		deleteDomain: async ({ commit }) => {
			const response = await Clients.deleteDomain();
        },
		setTrialResponse: async ({ commit }, seenTrialResponse) => {
			commit("setTrialResponse", seenTrialResponse);
        },
		
		setHeaderDomain: async ({ commit, dispatch }, domain) => {
			commit("setHeaderDomain", domain);

			try {
				await dispatch('init');
				return true;
			} catch (error) {
				this.$router.push({name: "error"})
			}
		
				

        },
		clients: async ({ commit }) => {
			const response = await Clients.clients();
            return response;
        },
    },
};
