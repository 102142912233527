import _ from "lodash";
import Api from "./api";
import store from "./../../store";

class Init extends Api {
    constructor() {
        super();
        this.uri = "";
        this.requiresAuth = false;
    }

    init(app) {
        app = app ? app : "backoffice";

        return this.post(
            `${this.uri}/init`,
            {
                app_token: app,
                identity: store.getters.identity
            },
            this.requiresAuth
        );
    }
}

export default new Init();
