import _ from "lodash";
import Api from "../api";
import store from "../../../store";

class Types extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/types";
        this.requiresAuth = false;
    }

    getTypes(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }
    
    getType(id) {
        let url = this.uri +"/" + id ;
        return this.get(url, {}, this.requiresAuth);
    }

    searchTypes(params, data) {
        let url = this.uri + "/search" + (params ? "?" + params : "");
        return this.post(url, data, this.requiresAuth);
    }

	updateType(params, id) {
        let url = this.uri + "/" + id;
        return this.put(url, params, this.requiresAuth);
    }

	createType(params) {
        let url = this.uri;
        return this.post(url, params, this.requiresAuth);
    }

    deleteType(id) {
        let url = this.uri +"/" + id ;
        return this.delete(url, {}, this.requiresAuth);
    }
}

export default new Types();
