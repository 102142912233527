<template>
  <draggable
    v-model="info"
    :class="'level level-' + level"
    @drag="startDrag"
    @dragenter="verifyDropzone"
    @drop="endDrag"
    :key="update"
  >
    <template v-slot:item="{ item }">
      <div class="item" :id="item.id">
        <i />
        <SimpleSelect
          @select-value="setOrder($event, item)"
          :previewIcon="'i-order'"
          :itemOrder="item.ordermenu"
          :itemId="item.id"
          :options="
            orderOptions.map((o) => {
              return { ...o, checked: item.ordermenu == o.value };
            })
          "
          :key="item.id"
        />
        <label>{{ item.name }}</label>
        <Switch
          :enabled="item.published == 1"
          @input="item.published = $event"
        />
        <ContextMenu :icon="'i-settings'" :options="[
          {label:$t('edit'), func: function(){editItem(item)}},
          {label:$t('up_level'), disabled: level == 1,func: function(){levelUp(item)}},
          {label:$t('down_level'), disabled: info.length <= 1,func: function(){levelDown(item)}},
          {label:$t('remove'), func: function(){removeItem(item.id)}}
        ]"/>
        <span
          :id="'collapse_'+item.id"
          :ref="'collapse_'+item.id"
          v-show="item.children.length > 0"
          @click="collapseExpand($event, item)"
          :key="item.children.length"
          >Colapsar</span
        >
      </div>
      <MenuList
        :ref="'list_' + item.id"
        v-if="item.children"
        :parent="item.id"
        :grandparent="parent"
        :level="level + 1"
        :class="item.class ? item.class : ''"
        :menu="item.children"
        :types="types"
        @infoUp="updateInfo"
      />
    </template>
  </draggable>
  <teleport to="body">
    <div v-if="editing!=null" class="bg">
        <div class="edit-item">
            <label>{{editing.id ? 'Editar' : 'Criar'}} menu</label>
            <i class="i-close" @click="editing = null"/>
            <div class="form">
                <fyi-text
                    v-bind:value="editing.name"
                    v-on:input-text="editing.name = $event"
                    :label="'Nome'"
                    :placeholder="'Nome'"
                    :icon_box="false" :full="true"
                />
                <fyi-text
                    v-bind:value="editing.description"
                    v-on:input-text="editing.description = $event"
                    :label="'Texto Alternativo'"
                    :placeholder="'Texto Alternativo'"
                    :icon_box="false" :full="true"
                />
                <label v-if="!editing.id">Localização</label>
                <ComplexSelect ref="location" v-if="!editing.id" :className="'filter'" :optionsLabel="''" :previewLabel="'Escolha a localização do menu'" :options="availableLocations(menu, 1)" 
                :noButtons="true" @clickLine="selectLocation" :singleSelection="true"/>
                <Upload :mime="'image'" :field="{field:{languages: [{label: 'Imagem'}], required: 0}, value: editing.image}" @upload="editing.image = $event"/>
                <SimpleSelect 
                    :label="'Especificação'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="editing.url_type = $event" :options="[{
                        label: 'Externo',
                        value: 'external',
                        checked: editing.url_type == 'external'
                    },
                    {
                        label: 'Tipo',
                        value: 'type',
                        checked: editing.url_type == 'type'
                    },
                    {
                        label: 'Conteúdo',
                        value: 'content',
                        checked: editing.url_type == 'content'
                    },]"/>
                <SimpleSelect 
                    v-if="editing.url_type == 'type' || editing.url_type == 'content'"
                    :label="'Tipo'"
                    :className="'order'"
                    :allowSearch="true"
                    :previewIcon="'i-order'"
                    @select-data-value="fetchContent($event)" :options="types.map( t => {return {label: t.languages[0].name,value: t.id, checked: editing.type == t.id }})" :key="1"/>

                <SimpleSelect 
                    v-if="editing.url_type == 'content'"
                    :label="'Conteúdo'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    :allowSearch="true"
                    @select-data-value="editing.url = $event" :options="editingContent"/>

                <fyi-text
                    v-if="editing.url_type == 'external'"
                    v-bind:value="editing.url"
                    v-on:input-text="editing.url = $event"
                    :label="'Url'"
                    :icon_box="false" :full="true"
                />
            </div>
            <div class="button-bar">
                <fyi-button
                    :label="editing.id ? 'Atualizar conteúdo' : 'Criar menu'"
                    :className="'default add'"
                    :onclick="() => edit()"
                />
                <fyi-button
                    :label="'Cancelar'"
                    :className="'default external'"
                    :onclick="() => editing = null"
                />
            </div>
        </div>
    </div>
  </teleport>
</template>

<script>
import Draggable from "vue3-draggable";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MenuList",
  components: {
    Draggable,
  },
  props: {
    menu: Array,
    level: Number,
    types: Array,
    parent: {
      type: Number,
      default: 0,
    },
    grandparent: {
        type: Number,
        default: 0,
    }
  },
  data() {
    return {
      info: [],
      editingContent: [],
      editing: null,
      update: 1,
    };
  },
  watch: {
    info(_old, _new) { 
      if(this.info.length == 0){
        if(document.querySelector("#collapse_" + this.parent)) document.querySelector("#collapse_" + this.parent).style.display = "none"
      }
      this.info.forEach((m, k) => {
        m.ordermenu = k + 1;
      });

      this.$emit('infoUp', {data: this.info, parent: this.parent});
    },
  },
  beforeMount() {
    this.info = this.menu;
  },
  computed: {
    orderOptions() {
      let list = [];
      for (let i = 0; i < this.info.length; i++) {
        list.push({
          label: i + 1,
          value: i + 1,
        });
      }
      return list;
    },
  },
  methods: {
    ...mapActions(["getContents", "getContent"]),
    startDrag(evt) {
      if (document.querySelector(".block-edit.menu"))
        document.querySelector(".block-edit.menu").style.minHeight =
          document.querySelector(".block-edit.menu").clientHeight + "px";

        evt.stopPropagation();
    },
    endDrag(evt) {
      setTimeout(() => {
        this.info.forEach((m, k) => {
          m.ordermenu = k + 1;
        });
      }, 50);

      if (document.querySelector(".block-edit.menu"))
        document.querySelector(".block-edit.menu").style.minHeight = "";

        let target = evt.target;
        while(!target.classList.contains('level')){
            target = target.parentElement
        }

        if(target.previousElementSibling != null){            
            target.previousElementSibling.lastElementChild.style.display = ''
            target.previousElementSibling.lastElementChild.innerHTML = 'Colapsar'
        }

        evt.stopPropagation();
    },
    verifyDropzone(evt){
      if(evt.target.classList.contains("hide")){
        evt.target.classList.remove("hide")
      }
    },
    collapseExpand(evt, item) {
      evt.target.parentElement.nextElementSibling.classList.toggle("hide");
      evt.target.parentElement.nextElementSibling.classList.contains("hide")
        ? (evt.target.innerHTML = "Expandir")
        : (evt.target.innerHTML = "Colapsar");
      item.class =
        evt.target.parentElement.nextElementSibling.classList.contains("hide")
          ? "hide"
          : "";
    },

    setOrder(newOrder, item) {
      //create clone of attachments to make only 1 update to the UI, instead of 1 for each item changed
      let menu = this.info;
      let oldOrder = item.ordermenu;

      menu.forEach((m) => {
        //give new order to updated item
        if (m.id == item.id) {
          m.ordermenu = parseInt(newOrder);
          //all items before the updated item decrease by 1
        } else if (
          oldOrder < newOrder &&
          m.ordermenu <= newOrder &&
          m.ordermenu >= oldOrder
        ) {
          m.ordermenu -= 1;
          //all items after the updated item increase by 1
        } else if (
          oldOrder > newOrder &&
          m.ordermenu >= newOrder &&
          m.ordermenu < oldOrder
        ) {
          m.ordermenu += 1;
        } else {
          m.ordermenu = m.ordermenu;
        }
      });

      menu.sort((a, b) => {
        return a.ordermenu >= b.ordermenu ? 1 : -1;
      });

      this.info = menu;
      this.update++;
    },

    removeItem(id) {
      this.info.forEach((m, k) => {
        if (m.id == id) {
          this.info.splice(k, 1);
        }
      });

      this.info.forEach((m, k) => {
        m.ordermenu = k + 1;
      });
      this.update++;
    },
    gatherInfo() {
      let m = [];
      let data = this.info;
      data.forEach((d) => {
        m.push({
          name: d.name,
          ordermenu: d.ordermenu,
          published: d.published,
          show_name: d.show_name,
          url: d.url,
          url_type: d.url_type,
          link: d.link,
          options: d.options,
          image: d.image,
          language_id: d.language_id,
          elements: this.$refs["list_" + d.id].gatherInfo(),
        });
      });

      return m;
    },
    async editItem(item){
      let clone = item
      if(clone.url_type == 'type')
        clone.type = clone.url
      else if(clone.url_type == 'content'){
        let c = await this.getContent({params: "", id: clone.url});
        await this.fetchContent(c.type.id, clone);
        clone.type = c.type.id
      }
      this.editing = clone
    },
    edit(){
      if(this.editing.name.trim() == ''){
        return
      }
    
      if(this.editing.id){
        this.info.forEach( i => {
            if(i.id == this.editing.id){
                i = this.editing 
            }
        })
      }else{
        this.locateAndAddItem(this.editing.parent, this.editing)
      }

        this.editing = null
    },
    locateAndAddItem(parent = 0, item){
      this.info.forEach( i => {
        if(parent == 0 && item.id == undefined){
          item.children = []
          item.published = 1
          item.language_id = 1
          item.id = Math.floor(Math.random() * 100000)
          this.info.push(item)
          this.info.forEach((m, k) => {
              m.ordermenu = k + 1;
            });
          this.update++;
          return true
        }else if(this.$refs['list_'+i.id].parent){
          if(this.$refs['list_'+i.id].parent == parent){
            item.children = []
            item.published = 1
            item.language_id = 1
            item.id = Math.floor(Math.random() * 100000)
            this.$refs['list_'+i.id].info.push(item)
            this.$refs['list_'+i.id].info.forEach((m, k) => {
              m.ordermenu = k + 1;
            });
            this.$refs['list_'+i.id].update++;
            return true
          }else{
            let added = this.$refs['list_'+i.id].locateAndAddItem(parent, item);
            if(added){
              return
            }
          }
        }
      })

      if(this.info.length == 0){
        item.children = []
        item.published = 1
        item.language_id = 1
        item.id = Math.floor(Math.random() * 100000)
        this.info.push(item)
        this.info.forEach((m, k) => {
          m.ordermenu = k + 1;
        });
        this.update++;
        return true
      }
      
      return false
    },
    levelUp(item){
      this.info.forEach( (i,k) => {
        if(i.id == item.id)
          this.info.splice(k,1)
      })

      this.$parent.$parent.$parent.$parent.info.push(item)

      this.info.forEach((m, k) => {
        m.ordermenu = k + 1;
      });

      this.$parent.$parent.$parent.$parent.info.forEach((m, k) => {
          m.ordermenu = k + 1;
        });

      this.update++;
      this.$parent.$parent.$parent.$parent.update++;
    },
    levelDown(item){
      let newParent = 0;
      this.info.forEach( (i,k) => {
        
        if(i.id == item.id){
          if(k == (this.info.length - 1) && k != 0){
            newParent = this.info[k-1].id
          }else{
            newParent = this.info[k+1].id
          }
          this.info.splice(k,1)
        }
      })

      this.$refs['list_'+newParent].info.push(item)
      this.$refs['list_'+newParent].info.forEach( (m, k) => {
        m.ordermenu = k + 1;
      });
      this.info.forEach( (m, k) => {
        m.ordermenu = k + 1;
      });
      this.$refs['list_'+newParent].update++
      this.update++;
    },
    async fetchContent(t, info = null){
      let array = this.editing

      if(info != null)
        array = info

      array.type = t; 

      if(array.url_type == "type")
        array.url = t; 
      
      if(array.url_type == "content"){
          let c = await this.getContents('noaddons=true&limit=99999999999999&order=date_desc&types='+array.type);
          let content = [];
          c.data.forEach( cc => {
              content.push({
                  label: cc.languages[0].name,
                  value: cc.id,
                  checked: array.url == cc.id
              })
          })
          this.editingContent = content;
      }
    },
    availableLocations(m, lvl, start = true){
      let filters = [];

      if(start)
        filters = [{value: 0, label:"Nível Base", class: "level-1"}];

      m.forEach( t => {
        let tmp = {};
        tmp.value = t.id
        tmp.label = t.name
        tmp.class = "level-" + lvl
        let child = this.availableLocations(t.children, lvl+1, false)
        filters.push(tmp)
        filters = filters.concat(child);
      })

      return filters;
    },
    selectLocation(id){
      this.editing.parent = id;
      this.$refs.location.opened = false
    },
    updateInfo(evt){
      this.info.forEach( i => {
        if(i.id == evt.parent){
          i.children = evt.data
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/menus.scss";   
</style>