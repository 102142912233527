import _ from "lodash";
import Api from "./api";
import store from "../../store";

class Languages extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/languages";
        this.requiresAuth = false;
    }

    getLanguages(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }
    
    getLanguage(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.get(url , {}, this.requiresAuth);
    }
}

export default new Languages();
