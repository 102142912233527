<template>
    <div class="fields">
        <div class="page-content">
            <Table ref="table" :tableClass="'extra-fields'" :items="fields" :paginationLoading="paginationLoading" :loading="loadTable" :limit="limit"
            @handlePagination="handlePagination" @apply-order="updateOrder" @apply="updateFilter($event)" @filter-type="updateFilter({value:$event})"
            @search="searchValue = $event" :availableEntries="availableEntries" @entry-select="setLimit($event)" :optionButtons="optionButtons"
            @remove="removeItem" @toggleSwitch="toggleSwitch" @edit="editItem"/>
        </div>
        <teleport to="body">
            <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
        </teleport>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name:"Fields",    
    data() {
        return {
            loading: true,
            limit: 9,
            limitTimeout: null,
            limitOnResize: true,
            order: 'date_desc',
            content_state: 'both',
            fields: [],
            availableEntries:[
                {
                    label: "Default",
                    value: "default",
                    checked: true
                },
                {
                    label: "20",
                    value: "20",
                    checked: false
                },
                {
                    label: "50",
                    value: "50",
                    checked: false
                },
                {
                    label: "100",
                    value: "100",
                    checked: false
                },
            ],
            optionButtons: [
                {
                    label: "Novo campo",
                    className: "new-field",
                    func: this.addField
                }
            ],
            loadTable: false,
            paginationLoading: false,
            filterOn: false,
            searchValue: "",
            toast_type: '',
            toast_message: '',
            toast_show: false
        }
    },
    async beforeMount(){
        this.changeLimit();
        this.fields = await this.getFields('paginate=true&limit='+this.limit);
        this.$parent.$parent.transition = false;
    },
    created() {
        window.addEventListener("resize", this.changeLimit);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeLimit);
    },
    watch: {
        async searchValue(_new, _old){
            if(_new.trim() == "" && _old == '' )
                return

            await this.search()
        }
    },
    methods:{
        ...mapActions(["getFields", "deleteField"]),
        //handles pagination requests
        async handlePagination(page){
            //set current table size to maintain gap for the pagination widget so it doesnt move around
            this.$refs.table.$el.querySelector(".pagination").style.top = this.$refs.table.$el.querySelector(".template-table").clientHeight + 47 + "px";

            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            //show loading inside the table element
            this.loadTable = true;

            this.fields = await this.getFields('paginate=true&limit='+this.limit+'&page='+page);

            //stop loading widget
            this.loadTable = false;
            this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        async removeItem(ids){
            ids = Array.isArray(ids) ? ids.join(",") : ids
            const ok = await this.$refs.modal.show({
                message: 'Deseja remover o conteúdo selecionado?',
                okButton: 'Remover conteúdo',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                await this.deleteField({id:ids});
                this.reload()
            }
        }, 
        async editItem(item){
            this.$router.push({ name: "fields_edit", params: { id: this.$route.params.id, field_id: item } })
        },
        async addField(item){
            this.$router.push({ name: "fields_create", params: { id: this.$route.params.id } })
        },
        async toggleSwitch(data){
            let { toggle, item} = data;
            event.stopPropagation();
            await this.updateBlock({data:{published:toggle}, id:item})            
        },
        async reload(){
            this.$refs.table.resetPage(1)
            if(this.searchValue){
                this.search()
                return
            }

            this.loadTable = true;
            this.paginationLoading = true;

            this.fields = await this.getFields('paginate=true&limit='+this.limit);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async search(page = false){
            this.loadTable = true;
            if(!page){
                this.paginationLoading = true;
                this.$refs.table.resetPage(1)
            }

            if(this.searchValue == '')
                this.fields = await this.getFields('paginate=true&limit='+this.limit);
            else if(page)
                 this.fields = await this.getFields('paginate=true&limit='+this.limit+'&page='+page);
            else
                this.fields = await this.getFields('paginate=true&limit='+this.limit+'&searchQuery='+ this.searchValue);

            this.loadTable = false;

            if(!page)
                this.paginationLoading = false;

            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        addItem(){
            if(this.curType == "" || this.curType.split(",").length > 1){
                this.toast_type = "error";
                this.toast_message = "Selecione apenas um tipo para poder criar um novo conteúdo.";
                this.toast_show = true;
                return
            }
            this.$router.push({ name: 'pages_cms_create', query: { id: this.curType }})
        },
        setLimit(value){
            //handles content limit set on the UI
            if(value != 'Default'){
                this.limitOnResize = false;
                this.limit = parseInt(value);
                if(this.fields.data != undefined){
                    this.reload();
                }
            }else{
                this.limitOnResize = true;
                this.changeLimit();
            }
        },
        changeLimit(){
            if(!this.$parent.$parent.$parent.$parent.expired && this.limitOnResize){
                //throttling so it doesnt make the same request while still performing a resize
                clearTimeout(this.limitTimeout);
                this.limitTimeout = setTimeout(() => {
                    let availableHeight = window.innerHeight - 144 - 144 - 88 - 30; // 144 - page header; 144 - table filters; 30 - table header; 88 - pagination element
                    let rows = 0, rowHeight = 60;
                    //while theres still available space increase the number of rows
                    while((availableHeight - rowHeight) > rowHeight){
                        availableHeight -= rowHeight;
                        rowHeight = rowHeight == 60 ? 44 : 60; //row height changes every other row
                        rows++;
                    }
                    
                    this.limit = rows == 0 ? 9 : rows //9 is default number of rows
                    //if there is content available reload
                    if(this.fields.data != undefined){
                        this.reload();
                    }
                }, 500)
            }
        }
    },
    mounted(){
        this.$parent.$parent.view = this.$t('fields')
    }
}
</script>

<style lang="scss">
    @import "../../../styles/pages/fields.scss";   
</style>