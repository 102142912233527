var global = {
    methods: {
        isAbsolute(path) {
            // check if path its absolute
            let absoluteFlag = /^https?:\/\//i.test(path);
            return absoluteFlag;
        },
        parseAbsolutePath(path) {
            return this.$store.getters.appConfig.url + path;
        }
    }
}

export default global;