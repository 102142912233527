import _ from "lodash";
import Api from "../api";

class Fields extends Api {
    constructor() {
        super();
        this.uri = "fields";
        this.requiresAuth = true;
    }

    all(params) {
        return this.get(this.uri + (params ? "?" + params : ""));
	}
}

export default new Fields();