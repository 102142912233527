<!-- https://loading.io/css/ -->

<template>
  <div class="switch">
    <label v-if="label && showLabel">{{ label }}</label>
	  <div class="slots">
		<slot name="icon"></slot>
		<slot name="label"></slot>
	  </div>
    <div class="switch-button-control">
      <div
        class="switch-button"
        :class="{ toggled: isToggled, disabled: isDisabled, disabled: disabled }"
        @click="toggle"
      >
        <div class="button"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Switch",
  model: {
    prop: "isToggled",
    event: "toggle"
  },
  props: {
    enabled: {
      type: [Number, String, Boolean],
      default: 0
    },
    id: {
      type: [Number, String]
    },
    rowid: {
      type: [Number, String]
    },
    label: {
      type: String,
      default: ""
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$emit("input", this.isToggled);
  },
  beforeDestroy() {

  },
  beforeMount() {
    if (Number(this.enabled) === 0 || this.enabled == false) {
      this.isToggled = false;
    } else {
      this.isToggled = true;
    }
  },
  data: function() {
    return {
      isToggled: Boolean,
      isDisabled: false
    };
  },
  methods: {
    toggle: function() {
      this.isToggled = !this.isToggled;
      this.$emit("click", this.isToggled);
      this.$emit("input", this.isToggled);
    },
    setToggle(rowId, alias, value, emit = true) {
      if (this.rowid == rowId && this.id == alias)
      {
        this.isToggled = value;

        if (emit) {
          this.$emit("click", this.isToggled);
          this.$emit("input", this.isToggled);
        }
      }
    }
  }
};
</script>

<style lang="scss">
// For switch-button styling
.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;

  .switch-button {
    $switch-button-height: 24px;
    $switch-button-color: $green;
    $switch-button-border-thickness: 1.5px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    margin: 0;
    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2 + 2px);
    border: $switch-button-border-thickness solid $light-grey;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px
      rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);

    transition: $switch-transition;

    $button-side-length: 24px;

    cursor: pointer;
    background-color: $light-grey;
    .button {
      height: $button-side-length;
      width: $button-side-length;
      border: $switch-button-border-thickness solid $light-grey;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: $white;

      transition: $switch-transition;
      transform: translate( 0, -2px );
    }

    &.toggled {
      background-color: $switch-button-color;
      box-shadow: none;

      .button {
        background: white;
        transform: translate( calc( #{$switch-button-height} * 2 - 24px ), -2px );
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .switch-button-label {
    margin-left: 10px;
  }
}
</style>
