import Templates from "../../../libs/api/cms/templates";

export default {
    state: {
        templates: []
    },

    getters: {
        templates: state => state.templates
    },

    mutations: {
        setTemplates: (state, templates) => {
            state.templates = templates;
        }
    },

    actions: {
        getTemplates: async ({ commit }, params) => {
            const templates = await Templates.getTemplates(params);
            commit("setTemplates", templates);
			return templates;
        },

        getTemplate: async ({ commit }, { params, id }) => {
            const response = await Templates.getTemplate(params, id);
            return response
        },

        createTemplate: async ({
            commit
        }, data) => {
            const response = await Templates.createTemplate(data);
            return response;
        },

        updateTemplate: async ({ commit }, { params, data, id }) => {
            const response = await Templates.updateTemplate(params, data, id);
            return response
        },

        deleteTemplate: async ({
            commit
        }, id) => {
            await Templates.deleteTemplate(id);
        },
    }
};
