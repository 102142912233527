import Auth from "../../libs/api/auth";
const getPermission = (state, appName = "backoffice") => {
    let user = state.user;
    if (!user.name) return [];
    return user.roles.reduce((a, c) => {
        // roles app permission
        let app = c.apps ? c.apps.find(i => i.token === appName) : null;
        if (app && app.pivot)
            a = a.concat(app.pivot.permissions);

        return a;
    }, []);
};

export default {
    state: {
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        token: localStorage.getItem("token") || null
    },

    getters: {
        auth: state => ({
            user: state.user,
            token: state.token,
            isAuthenticated: !!state.token && !!state.user.name,
            isAdmin: state.user && state.user.roles ? state.user.roles.find(r => r.alias === "admin") !== undefined : false,
            permissions: getPermission(state),
            token: state.token
        }),
        hasPermission: state => (permission, app = null) => getPermission(state, app).includes(permission) || getPermission(state, app).includes("all"),
        hasAnyPermission: state => getPermission(state, app).length > 0
    },

    mutations: {
        setToken: (state, token) => {
            state.token = token;

            if (token == null) localStorage.removeItem("token");
            else localStorage.setItem("token", token);
        },
        setUser(state, user) {
            state.user = user;

            if (user == null) localStorage.removeItem("user");
            else localStorage.setItem("user", JSON.stringify(user));
        },
        setOtp(state, otp) {

            if (otp == null) localStorage.removeItem("otp");
            else localStorage.setItem("otp", otp);
        }
    },

    actions: {
        logout: async ({
            commit
        },{ flag } = {flag: true}) => {
            if(flag){
                const response = await Auth.logout();
            }

            commit("setToken", null);
            commit("setUser", {});
            commit("setApps", null);
        },
        login: async ({
            commit,
            dispatch
        }, {
            email,
            password
        }) => {
            const data = await Auth.login({
                email,
                password
            });

            const {
                access_token,
                name,
                id,
                phone,
                avatar,
                info,
                roles,
                backoffice
			} = data;
			
			if(roles.length === 0) {
				throw {status: 401}; //sem roles
			}
            

			if(!backoffice) {
				throw {status: 401}; //sem bo permi
            }

            commit("setToken", access_token);

            const apps = await dispatch("getApps");
			if(apps.length === 0) {
				throw {status: 401}; //sem apps sem permissao
			} else {
				let appids = apps.map(({id}) => id);
                
                let backofficeId = parseInt(process.env.VUE_APP_BACKOFFICE_ID);
				if(!appids.includes(backofficeId)) {
					throw {status: 401};
				}
            }
            
            commit("setUser", {
                name,
                id,
                phone,
                email,
                avatar,
                info,
                roles,
                apps
            });
        },
        loginOTP: async ({
            commit,
            dispatch
        }, {
            email,
            password
        }) => {
            const data = await Auth.loginOTP({
                email,
                password
            });

           return data;
        },
        loginOTPValidate: async ({
            commit,
            dispatch
        }, {
            email,
            otp
        }) => {
            const data = await Auth.loginOTPValidate({
                email,
                otp
            });

            const {
                access_token,
                name,
                id,
                phone,
                avatar,
                info,
                roles,
                backoffice
			} = data;
			
			if(roles.length === 0) {
				throw {status: 401}; //sem roles
			}
            

			if(!backoffice) {
				throw {status: 401}; //sem bo permi
            }

            commit("setToken", access_token);

            const apps = await dispatch("getApps");
			if(apps.length === 0) {
				throw {status: 401}; //sem apps sem permissao
			} else {
				let appids = apps.map(({id}) => id);
                
                let backofficeId = parseInt(process.env.VUE_APP_BACKOFFICE_ID);
				if(!appids.includes(backofficeId)) {
					throw {status: 401};
				}
            }
            
            commit("setUser", {
                name,
                id,
                phone,
                email,
                avatar,
                info,
                roles,
                apps
            });
        },
        revoke: async ({
        }) => {
           return await Auth.logout();
        },
        recover: async ({}, {
            email
        }) => {
            await Auth.recover({
                email
            });
        },
        reset: async ({}, {
            password,
            token
        }) => {
            await Auth.reset({
                password,
                token
            });
        },
        revokePasswordChange: async({}, {
            user_id
        }) => {
            await Auth.revokePasswordChange({
                user_id
            });
        },
        validatePassword: async({}, {
            user_id, password
        }) => {
            return await Auth.validatePassword({
                user_id,
                password
            });
        },
        getUserByToken: async ({}, {
            token
        }) => {
            const data = await Auth.getUserByToken({
                token
            });

            const {
                id,
                phone,
                email
            } = data;

            return {
                id,
                phone,
                email
            }
        },
        getUserByResetToken: async ({}, {
            token
        }) => {
            const data = await Auth.getUserByResetToken({
                token
            });

            const {
                id,
                phone,
                email
            } = data;

            return {
                id,
                phone,
                email
            }
        },
        sendOtp: async ({}, {
            id,
        }) => {
            await Auth.sendOtp({
                id,
            });
        },
        validateOtp: async ({}, {
            id,
            otp
        }) => {
            await Auth.validateOtp({
                id,
                otp
            });
        },
        updateUser: async ({}, {
            id,
            data
        }) => {
            await Auth.updateUser({
                id,
                data
            });
        },
        updatePassword: async ({}, {
            password,
            token
        }) => {
            await Auth.updatePassword({
                password,
                token
            });
        },
    }
};