<template>
    <div class="fyi-breadcrumb">
        <ul>
            <li v-for="(crumb, key) in crumbs" v-bind:key="key">
                <router-link :to="{path: crumb.to}">{{crumb.text}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'fyi-breadcrumb',
    props: {},
    data() {
        return  {}
    },
    computed: {
        crumbs() {
            let pathArray = this.$route.path.replace(/\/$/, '').split("/")
            pathArray.shift()


            let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
                let aux = {
                    path: path,
                    to: breadcrumbArray[idx - 1]
                        ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                        : "/" + path,
                    text: path
                }

                breadcrumbArray.push(aux)
                return breadcrumbArray
            }, [])

            breadcrumbs.unshift({
                path: '/',
                to: '/',
                text: 'home'
            })

            return breadcrumbs
        }
    }
}
</script>

<style lang="scss">
//@import "@/styles/components/navigation/breadcrumb";
</style>
