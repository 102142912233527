import _ from "lodash";
import Api from "../api";

class Addons extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/addons";
        this.requiresAuth = true;
    }

    createAttachmentCategory(data) {
        return this.post(this.uri + "/attachmentCategory/create", data, this.requiresAuth);
    }

    getAttachmentCategory() {
        return this.post(this.uri + "/attachmentCategory/index", {}, this.requiresAuth);
    }

	getAddons() {
        return this.get(this.uri + "?full=1", {}, this.requiresAuth);
	}

    getAddonNutritionUnits() {
        return this.get(this.uri + "/nutrition/units", {}, this.requiresAuth);
	}
}

export default new Addons();
