<template>
  <div class="app__general">
    <router-view :key="$route.fullPath"></router-view>
    <teleport to="body">
        <AuthExpired v-if="expired" />
    </teleport>
  </div>
</template>

<script>
import {  mapActions } from "vuex";

export default {
  name: 'app-general',
    data: function() {
        return{
            timeout: null,
            expired: false
        }
    },
    methods:{
        ...mapActions([
        "revoke",
        ]),
    },
    beforeUnmount(){
        clearTimeout(this.timeout);
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    },
    mounted(){
        this.instateSecurityMeasurement()
    },
    methods: {
        //Assuming mouse movent on the window identifies an action, every 15 min a user hasnt made any input his token is revoked and he has to login again
        instateSecurityMeasurement(){
            const _this = this;
            document.onmousemove = function(){
                if(!_this.authExpired){
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(function(){
                        _this.expired = true;
                    }
                    , 60000 * 15);
                }
            }
        }
    }
}
</script>

<style lang="scss">
    @import "@/styles/components/layout/common";
</style>
