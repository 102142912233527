import Languages from "../../../libs/api/languages";

export default {
    state: {
        languages: []
    },

    getters: {
        languages: state => state.languages,
    },

    mutations: {
        setLanguages: (state, languages) => {
            state.languages = languages;
        }
    },

    actions: {
        getLanguages: async ({ commit }, params) => {
            const response = await Languages.getLanguages(params);
            commit("setLanguages", response);
        },
        setLanguages: ({ commit }, languages) => {
            commit("setLanguages", languages);
        }
    }
};
