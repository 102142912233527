export default async ({
    store, to
}) =>  {
    if (!store.getters.auth.isAuthenticated)
        return {name:"login"}
    else {
        //check user has role
        let permissions = store.getters.auth.permissions;

        if (to.name === "401")
            return {name:"401"}
        else if (!permissions || permissions.length === 0)
            return {name:"401"}
        else if (permissions.includes('all')) 
            return true
            // check permission
        else if (to.meta && to.meta.permissions) {
            for (let i = 0; i < to.meta.permissions.length; i++) {
                if (!store.getters.hasPermission(to.meta.permissions[i], store.getters.app)) {
                    return {name:"401"}
                }
            }
            
            return true
        }else
            return true
    }
}