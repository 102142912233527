import _ from "lodash";
import Api from "../api";

class Notifications extends Api {
    constructor() {
        super();
        this.uri = "notifications";
        this.requiresAuth = true;
    }

    getNotifications(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    getNotificationsLog(params) {
        let url = this.uri + "/log" + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    markReadNotificationsLog(data) {
        let url = this.uri + "/logs/mark";
        return this.post(url, data, this.requiresAuth);
	}
	
	sendNotification(data) {
        let url = this.uri + "/send";
        return this.post(url, data, this.requiresAuth);
	}
}

export default new Notifications();