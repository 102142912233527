import Filemanager from "../../libs/api/filemanager";

export default {
    state: {
      files: null,
      basePath: null
    },
  
    getters: {
        filemanager: state => ({
            files: state.files,
            basePath: state.basePath,
        })
    },
  
    mutations: {
        setFiles: (state, files) => {
            state.files = files
        },
        setBasePath: (state, basePath) => {
            state.basePath = basePath
        },
    },
  
    actions: {
        list: async ({ commit }, params) => {
            let response = await Filemanager.getFiles(params)
            commit("setFiles", response.files);
            commit("setBasePath", response.url);
        },
        getFiles: async ({ commit }, params) => {
            let response = await Filemanager.getFiles(params)
            return response;
        },
        getExtensions: async ({ commit }) => {
            let response = await Filemanager.getExtensions()
            return response;
        },
        saveFiles: async ({ commit }, {files, nr}) => {
            let response = await Filemanager.saveFiles(files, nr)
            return response;
        },
        removeFiles:async ({ commit }, files) => {
            let response = await Filemanager.removeFiles(files)
            return response;
        },
        editFiles: async ({ commit }, params) => {
            let response = await Filemanager.editFiles(params)
            return response;
        },
        cleanFilemanager: ({ commit }) => {
            commit("setFiles", null);
            commit("setBasePath", null);
        }
    }
  }
  