<template>
    <div :class="className">
        <label class="go-back" @click="goBack()">{{$t('go_back')}}</label>
        <label class="title">{{$t('auth_code_title')}}</label>
        <label class="description">{{$t('auth_code_description')}}</label>
        <label class="timer">{{$t('timer_label')}} {{timer_label + timer}}</label>
        <div class="otp-input-container">
            <fyi-number
                v-bind:value="otp_1"
                v-on:input-number="otp_1 = $event"
                :classe='"char"'
                :alias="'otp-1'"
                :maxlength='"1"'
                :show="true"
                :placeholder="''"
                :disabled="false"
                :required="true"
                :inputError="false"
                @paste="handlePaste($event)"
                @submit="handleSubmitOTP"
                @keyhandler="handleInput($event)"
            />
            <fyi-number
                v-bind:value="otp_2"
                v-on:input-number="otp_2 = $event"
                :classe='"char"'
                :alias="'otp-2'"
                :maxlength='"1"'
                :show="true"
                :placeholder="''"
                :disabled="false"
                :required="true"
                :inputError="false"
                @submit="handleSubmitOTP"
                @keyhandler="handleInput($event)"
            />
            <fyi-number
                v-bind:value="otp_3"
                v-on:input-number="otp_3 = $event"
                :classe='"char"'
                :alias="'otp-3'"
                :maxlength='"1"'
                :show="true"
                :placeholder="''"
                :disabled="false"
                :required="true"
                :inputError="false"
                @submit="handleSubmitOTP"
                @keyhandler="handleInput($event)"
            />
            <fyi-number
                v-bind:value="otp_4"
                v-on:input-number="otp_4 = $event"
                :classe='"char"'
                :alias="'otp-4'"
                :maxlength='"1"'
                :show="true"
                :placeholder="''"
                :disabled="false"
                :required="true"
                :inputError="false"
                @submit="handleSubmitOTP"
                @keyhandler="handleInput($event)"
            />
        </div>
        <fyi-button
            v-if="timer != ''"
            :label="$t('validate_code')"
            :className="'default'"
            :disabled="otp == '' || timer == ''"
            :loading="$parent.submitStatus=='PENDING'"
            :onclick="() => {handleSubmitOTP()}"
        >
        </fyi-button>
        <fyi-button
            :label="$t('resend_code')"
            :className="'default'"
            :loading="$parent.submitStatusResendOTP=='PENDING'"
            :onclick="() => {resendOTP()}"
        >
        </fyi-button>
        <fyi-button
            v-if="timer == ''"
            :label="$t('cancel')"
            :className="'default'"
            :onclick="() => {goBack()}"
        >
        </fyi-button>
    </div>
</template>

<script>
export default {
	name: "otp",
    props: {
		className: {
			type: String,
			default: ""
		},
    },
    data: function() {
        return {
            otp_1: '',
            otp_2: '',
            otp_3: '',
            otp_4: '',
            timer: '5m',
            timer_label: this.$t('expires_in'),
            stopwatch: null, 
        };
    },
    computed: {
		otp(){
			if(this.otp_1 !== '' && this.otp_2 !== '' && this.otp_3 !== '' && this.otp_4 !== '')
				return this.otp_1 + this.otp_2 + this.otp_3 + this.otp_4;

			return ''
		}
	},
    watch: {
        otp(_new, _old){
            this.$emit('otp', _new);
        },
        timer(_new, _old){
			if(_new == '0m' || _new == ''){
				this.timer = '';
				this.timer_label = this.$t('expired');
				this.handleTimer(true)
			}else{
				this.timer_label = this.$t('expires_in');
			}
		},
    },
    methods: {
        goBack(){
			this.otp_1 = '';
			this.otp_2 = '';
			this.otp_3 = '';
			this.otp_4 = '';
			this.handleTimer(true)
            this.$emit('go-back')
		},
		handleTimer( stop = false){
			const _this = this;
			clearInterval(this.stopwatch)
			if(!stop){
				this.timer = '5m'
				this.stopwatch = setInterval(function(){
					_this.timer = parseInt(_this.timer) - 1 + "m";
				}, 60000);
			}
		},
		handleInput(event){
			//if backspace
            if(event.keyCode == 8){
                if(event.target.value == '' && event.target.parentElement.previousElementSibling){
                    setTimeout(function(){
                        event.target.parentElement.previousElementSibling.querySelector("input").value = ''
                        event.target.parentElement.previousElementSibling.querySelector("input").select()
                        event.target.parentElement.previousElementSibling.querySelector("input").setSelectionRange(0, 1)
                    }, 1)
                }
            //if arrow left
            }else if(event.keyCode == 37){
				if(event.target.parentElement.previousElementSibling)
                    setTimeout(function(){
                        event.target.parentElement.previousElementSibling.querySelector("input").select()
                        event.target.parentElement.previousElementSibling.querySelector("input").setSelectionRange(0, 1)
                    }, 1)
                else{
                    event.stopPropagation();
                    event.preventDefault();
                }
            //if arrow right
			}else if(event.keyCode == 39){
                if(event.target.parentElement.nextElementSibling)
                    setTimeout(function(){
                        event.target.parentElement.nextElementSibling.querySelector("input").select()
                        event.target.parentElement.nextElementSibling.querySelector("input").setSelectionRange(0, 1)
					}, 1)
                else{
                    event.stopPropagation();
                    event.preventDefault();
                }
            }else if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
                //DO NOTHING IF APPLE DEVICE

            //if number pressed or if mobile (cant receive keycodes on mobile, but keyboard is "forced" to only numeric)
            }else if(!isNaN(event.key) || /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ //iPhone|iPad|iPod - removed because not working on safari
                //every other key if input
                if(event.target.parentElement.nextElementSibling){
                    setTimeout(function(){
                        event.target.parentElement.nextElementSibling.querySelector("input").select()
                        event.target.parentElement.nextElementSibling.querySelector("input").setSelectionRange(0, 1)
                    }, 1)
                    event.stopPropagation();
                }
            }
		},
        handlePaste(event){
            //get clipboard data
            let paste = event.clipboardData.getData("text")

            //in this case if pasted value not a number ignore and prevent subsequent behaviour
            if(isNaN(paste)){
                event.preventDefault();
                event.stopPropagation();
                return false;
            }

            this.otp_1 = paste[0] != undefined ? paste[0] : this.otp_1;
			this.otp_2 = paste[1] != undefined ? paste[1] : this.otp_2;
			this.otp_3 = paste[2] != undefined ? paste[2] : this.otp_3;
			this.otp_4 = paste[3] != undefined ? paste[3] : this.otp_4;

            //set caret position to the input after the pasted lenght (ex: if 3 number code pasted, set caret in the 4th input)
            let caret = 5 - paste.length - 1

            //if caret is 0 it means the full code was pasted so we set it to the last input possible
            if(caret == 0)
                caret = 1

            //set caret position
            document.querySelectorAll("input")[document.querySelectorAll("input").length - caret].focus()

            //stop all subsequent behaviour
            event.preventDefault();
            event.stopPropagation();
        },
        resendOTP(){
            this.otp_1 = '';
			this.otp_2 = '';
			this.otp_3 = '';
			this.otp_4 = '';
            document.querySelector("input").focus();
            this.$emit('resend-otp', {});
		},
		handleSubmitOTP(){
			this.$emit('submit-otp', {otp: this.otp, timer: this.timer});
		}
    },
    mounted(){
        this.handleTimer();
        setTimeout(function(){
            document.querySelector(".otp-input-container input").focus()
        }, 100)
    }
}
</script>

<style>
</style>