<template>
    <template v-for="(addon, k) in addons" :key="k">
        <AddonAttachments v-if="addon == 'attachment'" :content="content"
        @add-files-attachments="$emit('add-files-attachments', $event)" @remove-files-attachments="$emit('remove-files-attachments', $event)"
        />
        <AddonFields v-if="addon == 'field'" :content="content"
        @update-fields="fields = $event"
        />
        <AddonEvents v-if="addon == 'event'" :content="content"
        @update-events="events = $event"
        />
        <AddonRelated v-if="addon == 'related'" :content="content"
        @update-related="events = $event"
        />
        <AddonCategories v-if="addon == 'categorie'" :content="content"
        @update-categories="categories = $event"
        />
    </template>
</template>

<script>
export default {
    name:"ContentAddons",
    props: {
        addons: {
            type: Array,
            default: []
        },
        content: {
            type: Object,
            default: null
        }
    },
    data(){
        return{
            fields: [],
            events: [],
            categories: []
        }
    }
}
</script>

<style>

</style>