import Inbox from "../../libs/api/inbox";
import Activities from "../../libs/api/zelo/activities";
import store from "../../store"
export default {
    state: {
		refreshActivities: 0
    },

    getters: {
        refreshActivities: state => state.refreshActivities,
    },

    mutations: {
        refreshZeloActivities: (state) => {
            state.refreshActivities = state.refreshActivities + 1;
        }
    },

    actions: {
        newMessageAdmin: async ({}, data) => {
            await Inbox.storeMessage(data);
		},
		
        getInboxByUser: async ({}, {id, params}) => {
            return await Inbox.getInboxByUser(id, params);
		},
        getDashboardActivities: async ({}, params) => {
            return await Activities.getDashboardActivities(params);
        },
        getPresencasTable: async ({}, params) => {
            return await Activities.getPresencasTable(params);
        },
        setRefreshZeloActivities: async ({ commit }) => {
			// post base
			commit('refreshZeloActivities')
		},
			
        exportActivities: async ({
            commit
        }, params) => {
            const response = await Activities.export(params);
            return response
		},
    }
};