<template>
  <div class="app__auth">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app-auth'
}
</script>

<style lang="scss">

</style>
