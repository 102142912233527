import Fields from "../../../libs/api/users/fields";
import store from "../.."
export default {
    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        getUserFields: async ({
            commit
        }, params) => {
            const response = await Fields.all(params);
            return response
        },
    }
};