import Configs from "../../../libs/api/cms/configs";

export default {
    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
    }
};
