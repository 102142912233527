<template>
    <div class="header">
        <Search :disabled="true"/>
        <keep-alive>
        <img v-if="$store.getters.auth.user.avatar!=null" class="avatar" :src="$store.getters.auth.user.avatar">
        <div v-else :style="'background: #'+color" class="avatar">
            <span>{{ pseudo_name }}</span>
        </div>
        </keep-alive>
        <i class="i-notifications"></i>
        <i class="i-settings"></i>
        <i class="i-logout" @click="out"></i>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name:'Header',
    computed: {
        pseudo_name(){
            if(this.$store.getters.auth.user.name == undefined)
                return ''
                
            let splt = this.$store.getters.auth.user.name.split(" ");
            return splt[0][0].toUpperCase() + (splt[1] != undefined ? splt[1][0].toUpperCase() : '')
        },
        color(){
            if(window.sessionStorage.getItem('headerColor') == undefined || window.sessionStorage.getItem('headerColor') == null)
                window.sessionStorage.setItem('headerColor', (Math.random()*0xFFFFFF<<0).toString(16));

            return window.sessionStorage.getItem('headerColor');
        }
    },
    methods: {
        ...mapActions(["logout"]),
        async out(){
            const ok = await this.$refs.modal.show({
                message: "Deseja terminar a sessão?",
                okButton: 'Terminar Sessão',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.logout().then(() => {
                    this.$router.push({ name: "login" });
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/navigation/header.scss";
</style>