<template>
    <div class="dashboard users">
        <Sidebar :menu="menu"/>
        <div class="main">
            <Header/>
            <router-view></router-view> 
        </div>
    </div>
    <teleport to="body">
        <transition name="fade">
            <LoadingPage v-if="transition" />
        </transition>
    </teleport>
</template>

<script>
export default {
    data() {
        return {
            menu: [
                {
                    label:this.$t('dashboard'),
                    icon:"i-dashboard",
                    route:"dashboard",
                    params: {},
                    query: {},
                    permissions: []
                },
                {
                    label:this.$t('users'),
                    icon:"i-users",
                    route:"users",
                    //route:"none",
                    params: {},
                    query: {},
                    permissions: ["users"]
                },
                {
                    label:this.$t('apps'),
                    icon:"i-apps",
                    route:"none",
                    params: {},
                    query: {},
                    permissions: ["apps"]
                },
                {
                    label:this.$t('devices'),
                    icon:"i-devices",
                    route:"none",
                    params: {},
                    query: {},
                    permissions: ["devices"]
                }
            ],
            loading: false,
            transition: false,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/pages/dashboard.scss";   
</style>