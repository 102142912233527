<template>
  <div class="register">
    <p>Register</p>
  </div>
</template>

<script>

export default {
  name: 'Register'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
