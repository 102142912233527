<template>
	<div
		v-if="logo"
		@click.stop="redirect()"
		class="fyi-logo"
		:style="{backgroundImage: 'url(' + logo + ')'}"
	></div>
	<div v-else @click.stop="redirect()" class="fyi-logo"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "fyi-logo",
	props:{
		redirectOnClick: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapGetters(["clientInfo"]),
		logo() {
			if (this.clientInfo.logo && this.clientInfo.logo !== "") {
				return this.clientInfo.logo;
			} else if (
				process.env.VUE_APP_CLIENT_LOGO &&
				process.env.VUE_APP_CLIENT_LOGO !== ""
			) {
				return process.env.VUE_APP_CLIENT_LOGO;
			}

			return false;
		}
	},
	methods: {
		redirect() {
			if(this.redirectOnClick){
				if (
					process.env.VUE_APP_PAGE_DEFAULT &&
					process.env.VUE_APP_PAGE_DEFAULT !== "" &&
					process.env.VUE_APP_PAGE_DEFAULT !== "cms" &&
					this.$route.name !== process.env.VUE_APP_PAGE_DEFAULT
				) {
					this.$router.push({ name: process.env.VUE_APP_PAGE_DEFAULT });
				} else if (this.$route.name !== process.env.VUE_APP_PAGE_DEFAULT) {
					this.$router.push({ name: "dashboard" });
				}
			}
		}
	}
};
</script>

<style lang="scss">
.fyi-logo {
	cursor: pointer;
	height: 60px;
	width: 280px;
	background: url("../../../../assets/logo.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: auto;
}
</style>
