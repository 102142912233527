// https://itnext.io/setting-language-with-i18n-by-os-language-in-vue-router-vuex-e42c9318c9ec

import { createI18n } from 'vue-i18n'
import langs from '../locales';

const i18n = createI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  globalInjection: true,
  messages: langs,
});

export default i18n;