import * as components from './components'
// import './scss/main.scss'

export default {
  install: function(Vue, options) {
    for (let key in components) {
      let c = components[key]
      Vue.component(c.name, c)
    }
  }
}
