<template>
    <div :class="['media', {'modal-window': modal}]">
        <Table ref="table" :items="files"  :loading="loadTable" :limit="limit" :tableClass="'media'" :multiSelect="multiSelect"
        :availableOrders="availableOrders" :availableDirections="availableDirections" :availableStates="availableFilters" :optionButtons="optionButtons"
        @apply-order="updateOrder" @apply="updateFilter" :showLayout="true" @layoutChange="($event) => {if($event == 'grid') { limit = 40; reload() }else{ page=1; changeLimit() }}"
        @handlePagination="handlePagination" @search="searchValue = $event"  @checkboxSelected="selectedItems = $event" @onScrollRequest="page++; handleInfinite(page)"
        @edit="editFile($event)" @remove="removeFile" @preview="previewFile" @filter-type=" ($event) => {if($event != null) $router.push({ name: 'pages_cms_edit', params: { id: this.$route.params.id, content_id: $event } })}"
        :showEntries="false" :showTotal="false" :contextMenu="modal ? [] : contextMenu"
        />
        <div>
            <teleport to="body">
                <div v-if="addFlag" class="bg">
                    <div class="container">
                        <label>Upload de ficheiro</label>
                        <div class="drag-n-drop" v-bind="getRootProps()">
                            <input v-bind="getInputProps()" />
                            <i
                                class='i-upload'
                            />
                            <label>Largue aqui os seus ficheiros ou <span>pesquise</span></label>
                            <label>Max. 25MB para ficheiros e 8MB para vídeos.</label>
                            <label>Imagens do tipo TIFF não são aceites.</label>
                            <i
                                class='i-close'
                                :onclick="() => { closeUploadWindow() }"
                            />
                        </div>
                        <div class="items">
                            <template v-for="(p,k) in uploadProgress" :key="k">
                                <template v-if="p!=undefined">
                                    <div class="prog" v-for="(i,l) in p.files" :key="l">
                                        <label>{{i.name}}</label>
                                        <label>{{i.size}}</label>
                                        <div class="ko-progress-circle" :data-progress="p.prog">
                                            <div class="ko-circle">
                                                <div class="full ko-progress-circle__slice">
                                                    <div class="ko-progress-circle__fill"></div>
                                                </div>
                                                <div class="ko-progress-circle__slice">
                                                    <div class="ko-progress-circle__fill"></div>
                                                    <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                                                </div>
                                            </div>
                                            <div class="ko-progress-circle__overlay"><div id="tick-mark"></div></div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </teleport>
        </div>
        <div v-if="modal" class="button-bar">
            <fyi-button
                :label="'Adicionar'"
                :className="'default add'"
                :disabled="selectedItems.filter( sel => sel == true).length == 0"
                :onclick="() => modalAdd()"
            />
            <fyi-button
                :label="'Cancelar'"
                :className="'default cancel'"
                :onclick="() => $parent.mediaOn = false"
            />
        </div>
        <teleport to="body">
            <div v-if="editing!=null" class="bg">
                <div class="edit-file">
                    <label>Editar ficheiro</label>
                    <i class="i-close" @click="editing = null"/>
                    <div class="info">
                        <div class="preview">
                            <img v-if="editing.mime.includes('image')" :src="$store.getters.appConfig.url + editing.path">
                            <i v-else :class="setIconExtension(editing)" />
                        </div>
                        <div class="details">
                            <label><span>Criado:</span><span>{{            
                                new Date(editing.created_at).toLocaleDateString("pt-PT", {
                                format: "short",
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                })}}</span></label>
                            <label><span>Autor:</span><span>{{editing.user ? editing.user.name : '-'}}</span></label>
                            <label><span>Extensão:</span><span>{{editing.name.split(".")[editing.name.split(".").length-1]}}</span></label>
                            <label><span>Tamanho:</span><span>{{(editing.size / (1024 ** 2)).toFixed(3) + 'MB'}}</span></label>
                            <label><span>Dimensões:</span><span>{{editing.dimensions ? editing.dimensions : "-"}}</span></label>
                            <div class="bt">
                                <fyi-button
                                    :label="'Preview'"
                                    :icon="'far fa-eye'"
                                    :className="'default preview'"
                                    :onclick="() => { previewFile(editing)}"
                                />
                                <fyi-button
                                    :label="'Download'"
                                    :className="'default download'"
                                    :icon="'i-upload file'"
                                    :onclick="() => { downloadEditFile() }"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <fyi-text
                            v-bind:value="editing.name"
                            v-on:input-text="''"
                            :label="'Nome'"
                            :placeholder="'Nome'"
                            :icon_box="false" :full="true"
                            :disabled="true"
                        />
                        <fyi-text
                            v-bind:value="''"
                            v-on:input-text="''"
                            :label="'Texto Alternativo'"
                            :placeholder="'Texto Alternativo'"
                            :icon_box="false" :full="true"
                            :disabled="true"
                        />
                        <SimpleSelect 
                            :label="'Categorias'"
                            :className="'categories'"
                            @select-value="editing.category_id = $event" :previewIcon="'i-order'" :options="createDropdownInfo(categories, editing.category_id)"/>
                        <fyi-text
                            v-bind:value="editing.path"
                            :label="'Url'"
                            :disabled="true"
                            :icon_box="false" :full="true"
                        />
                        <InputTags
                            :tags="itemLocations(editing)"
                            :fullTags="itemLocations(editing, true)"
                            :label="'Localização'"
                            :disabled="true"
                            :icon_box="false" :full="true"
                        />
                    </div>
                    <div class="button-bar">
                        <fyi-button
                            :label="'Atualizar conteúdo'"
                            :className="'default add'"
                            :onclick="() => edit()"
                        />
                        <fyi-button
                            :label="'Eliminar'"
                            :className="'default external'"
                            :onclick="() => removeFile(editing.id)"
                        />
                    </div>
                </div>
            </div>
        </teleport>
        <teleport to="body">
            <div v-if="editingCategories" class="bg">
                <div class="edit-categories">
                    <SimpleSelect 
                    :label="'Categorias'"
                    :className="'categories'"
                    @select-value="multiCate = $event" :previewIcon="'i-order'" :options="createDropdownInfo(categories)"/>
                    <div class="button-bar">
                        <fyi-button
                            :label="'Atualizar conteúdos'"
                            :className="'default add'"
                            :onclick="() => editCategories()"
                        />
                        <fyi-button
                            :label="'Cancelar'"
                            :className="'default external'"
                            :onclick="() => editingCategories = false"
                        />
                    </div>
                </div>
            </div>
        </teleport>
        <teleport to="body">
            <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
        </teleport>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex'
import {ref} from 'vue';
import $bus from '../../../../plugins/event'

export default {
    name:"Media",
    data(){
        return {
            files: [],
            selectedItems: [],
            loadTable: false,
            page: 1,
            limit: 9,
            limitTimeout: null,
            searchValue: '',
            filter: '',
            availableFilters: [
            ],
            availableOrders: [
                {
                    alias:"name",
                    label:this.$t('name'),
                    checked: false
                },
                {
                    alias:"created_at",
                    label:this.$t('date_created'),
                    checked: true
                },
                {
                    alias:"updated_at",
                    label:this.$t('date_updated'),
                    checked: false
                },
            ],
            availableDirections:[
                {
                    alias:"asc",
                    label:this.$t('asc'),
                    checked: false
                },
                {
                    alias:"desc",
                    label:this.$t('desc'),
                    checked: true
                },
            ],
            contextMenu: [
                {label:this.$t('Eliminar ficheiros'), class:"i-remove", func: this.removeSelected},
                {label:this.$t('Editar categorias'), class:"i-plus", func: this.openCategories},
            ],
            order: "created_at_desc",
            optionButtons: [
                {
                    label: "Novo conteúdo",
                    className: "new-content",
                    func: this.addFile
                }
            ],
            addFlag: false,
            uploadProgress: [],
            filters_image: [
                    {
                        label: 'PNG',
                        alias: "png",
                        checked: false
                    },
                    {
                        label: 'JPEG',
                        alias: "jpeg",
                        checked: false
                    },
                    {
                        label: 'GIF',
                        alias: "gif",
                        checked: false
                    },
                    {
                        label: 'SVG',
                        alias: "svg",
                        checked: false
                    }
                ],
            filters_video: [
                {
                    label: 'MP4',
                    alias: "mp4",
                    checked: false
                },
                {
                    label: 'MOV',
                    alias: "mov",
                    checked: false
                },
                {
                    label: 'WMV',
                    alias: "wmv",
                    checked: false
                },
                {
                    label: 'AVI',
                    alias: "avi",
                    checked: false
                },
            ],
            filters_doc: [
                {
                    label: 'DOC',
                    alias: "doc",
                    checked: false
                },
                {
                    label: 'XLS',
                    alias: "xls",
                    checked: false
                },
                {
                    label: 'PDF',
                    alias: "pdf",
                    checked: false
                },
                {
                    label: 'CSV',
                    alias: "csv",
                    checked: false
                },
            ],
            requestedFilters: [],
            categories: [],
            editing: null,
            editingCategories: false,
            multiCate: null,
            toast_type: '',
            toast_message: '',
            toast_show: false,
        }
    },
    setup() {
        const store = useStore()
        var showModal = ref(0)
        var addFlag = ref(false)
        var count = 0

        function onDrop(acceptFiles, rejectReasons) {
            saveFiles(acceptFiles); // saveFiles as callback
            addFlag = false
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

        const saveFiles = (files) => {
            let flagError = false
            files.forEach( f => {
                //partly deprecated due to implementation of image compression server side (compression doesnt work php side for tiff so we enforce the size threshold)
                if(f.type.includes("image/tif")){
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 2MB.";
                    // this.toast_show = true;
                    showModal = 2
                    flagError = true
                }

                if(f.type.includes("video") && f.size > 8000000){
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 8MB.";
                    // this.toast_show = true;
                    showModal = 8
                    flagError = true
                }

                if(f.size > 25000000 && !f.type.includes("image")){
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 25MB.";
                    // this.toast_show = true; 
                    flagError = true
                    showModal = 25
                }
            })
            if(flagError){
                $bus.$trigger('modal', showModal)
                return
            }

            store.dispatch('saveFiles', {files:files, nr: count});
            count = count + Math.ceil(files.length / 5)
        };
        return {
            getRootProps,
            getInputProps,
            addFlag,
            showModal,
            ...rest,
            showModal
        };
    },
    props: {
        modal: {
            type: Boolean,
            default: false
        },
        mime:{
            type: String,
            default: ''
        },
        multiSelect: {
            type: Boolean,
            default: true
        },
        destination:{
            type: String,
            default: null
        },
    },
    watch: {
        async searchValue(_new, _old){
            if(_new.trim() == "" && _old == '' )
                return

            await this.search()
        },
        async order(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.files = await this.getFiles('limit='+this.limit+"&order="+this.order+'&mime='+this.mime+'&filter='+this.filter);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async filter(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.files = await this.getFiles('limit='+this.limit+"&order="+this.order+'&mime='+this.mime+'&filter='+this.filter);

            this.loadTable = false;
            this.paginationLoading = false;
        }
    },
    async beforeMount(){

        this.loadTable = true;
        this.changeLimit(0);
        let rf = await this.getExtensions();
        for (const key in rf) {
            this.requestedFilters.push(
                {
                    label: rf[key].extension.toUpperCase(),
                    alias: rf[key].extension,
                    checked: false
                }
            )
        }

        
        this.availableFilters = this.setFilters();
        this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&mime="+this.mime+"&filter="+this.filter)
        this.categories = await this.getAddonsAttachmentsCategories()
        this.loadTable = false;
        
        if(!this.modal)
            this.$parent.transition = false;
    },
    created() {
        window.addEventListener("resize", this.changeLimit);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeLimit);
    },
    methods: {
        ...mapActions(["getFiles", "getExtensions", "saveFiles", "removeFiles", "getAddonsAttachmentsCategories", "editFiles"]),
        //handles pagination requests
        async handlePagination(page){
            //set current table size to maintain gap for the pagination widget so it doesnt move around
            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style.top = this.$refs.table.$el.querySelector(".template-table").clientHeight + 87 + "px";

            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            //show loading inside the table element
            this.loadTable = true;

            this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+'&page='+page+"&mime="+this.mime+"&filter="+this.filter)

            //stop loading widget
            this.loadTable = false;
            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        async handleInfinite(page){
            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            let files = await this.getFiles('limit='+this.limit+'&order='+this.order+'&page='+page+"&mime="+this.mime+"&filter="+this.filter)
            let data = this.files.data.concat(files.data)
            this.files.data = data;

            this.$refs.table.scrollCooldown = false;
        },
        updateOrder(info){
            let order = ''
            for(let k in info["states"][0])
                if(info["states"][0][k]) order = k+"_"

            for(let k in info["states"][1])
                if(info["states"][1][k]) order += k
            
            this.order = order
        },
        updateFilter(info){
            let filter = ""
            if(info !== null){
                for(let k in info["states"][0]){
                    if(info["states"][0][k]){
                        filter = k
                        break;
                    }
                }
            }
            this.filter = filter;
        },
        async search(page = false){
            this.loadTable = true;

            if(!page){
                this.paginationLoading = true;
                this.$refs.table.resetPage(1)
            }

            if(this.searchValue == '')
                this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&mime="+this.mime+"&filter="+this.filter);
            else if(page)
                this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&name="+this.searchValue+"&page="+page+"&mime="+this.mime+"&filter="+this.filter);
            else
                this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&name="+this.searchValue+"&mime="+this.mime+"&filter="+this.filter);

            this.loadTable = false;

            if(!page)
                this.paginationLoading = false;

            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        async searchInfinite(page = false){
            this.loadTable = true;

            let files = []

            if(this.searchValue == '')
                files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&mime="+this.mime+"&filter="+this.filter);
            else if(page)
                files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&name="+this.searchValue+"&page="+page+"&mime="+this.mime+"&filter="+this.filter);
            else
                files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&name="+this.searchValue+"&mime="+this.mime+"&filter="+this.filter);
                
            let data = this.files.data.concat(files.data)
            this.files.data = data;

            this.loadTable = false;
            this.$refs.table.scrollCooldown = false;
        },
        async reload(){
            this.editing = null
            this.$refs.table.resetPage(1)
            
            if(this.searchValue){
                this.search()
                return
            }

            this.loadTable = true;
            this.paginationLoading = true;
            this.files = await this.getFiles('limit='+this.limit+'&order='+this.order+"&name="+this.searchValue+"&mime="+this.mime+"&filter="+this.filter);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        changeLimit(throttle = 500){
            //throttling so it doesnt make the same request while still performing a resize
            if(!this.$parent.$parent.$parent.$parent.expired && (throttle == 0 || (this.$refs.table && this.$refs.table.layoutView == 'lines'))){
                clearTimeout(this.limitTimeout);
                this.limitTimeout = setTimeout(() => {
                    let availableHeight = window.innerHeight - 144 - 144 - 30 - 88 - 30; // 144 - page header; 81 - table filters; 30 - table header; 88 - pagination element
                    if(this.modal)
                        availableHeight -= 90;
                    let rows = 0, rowHeight = 60;
                    //while theres still available space increase the number of rows
                    while(availableHeight > rowHeight){
                        availableHeight -= rowHeight;
                        rowHeight = rowHeight == 60 ? 44 : 60; //row height changes every other row
                        rows++;
                    }
                    this.limit = rows == 0 ? 9 : rows //9 is default number of rows
                    
                    //if there is content available reload
                    if(this.files.data != undefined || throttle == 0){
                        this.reload();
                    }
                }, throttle)
            }
        },
        setFilters(){
            let filters = []
            if(this.mime == "image"){
                filters = this.filters_image
            }else{
                //filters = this.filters_doc.concat(this.filters_image).concat(this.filters_video)
                filters = this.requestedFilters
            }
            
            return filters;

        },
        previewFile(file){
            //TO DO - add plugin
            window.open(this.$store.getters.appConfig.url + file.path, "_blank");
        },
        addFile(){
            this.addFlag = true;
        },
        editFile(file){
            let f = this.files.data.filter( fi => fi.id == file)[0]
            this.editing = f
        },
        openCategories(){
            this.editingCategories = true
        },
        async editCategories(){
            let all = []
            this.selectedItems.forEach( (i,k) => {
                if(i){
                    this.files.data.forEach( f => {
                        if(f["id"] == k){
                            f.category_id = this.multiCate;
                            all.push(f)
                        }
                    })
                }
            })
            await this.editFiles({files:all})
            this.toast_type = "success";
            this.toast_message = "Categoria editada com sucesso.";
            this.toast_show = true;
            this.editingCategories = false
            this.multiCate = null;
            this.reload()
        },
        async edit(){
            await this.editFiles({files:[this.editing]})
            this.editing = null
        },
        downloadEditFile(){
            //Create XMLHTTP Request.
            let req = new XMLHttpRequest();
            req.open("GET", this.$store.getters.appConfig.url + this.editing.path, true);
            req.responseType = "blob";
            req.onload = () => {
                //Convert the Byte Data to BLOB object.
                let blob = new Blob([req.response], { type: "application/octetstream" });
 
                //Check the Browser type and download the File.
                let isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, this.editing.name);
                } else {
                    let url = window.URL || window.webkitURL;
                    let link = url.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.setAttribute("download", this.editing.name);
                    a.setAttribute("href", link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };
            req.send();

        },
        async removeFile(id){
            const ok = await this.$refs.modal.show({
                message: 'Deseja remover o ficheiro selecionado?',
                okButton: 'Remover ficheiro',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                await this.removeFiles([{id:id}])
                this.reload();
            }
        },
        async removeSelected(id){
            const ok = await this.$refs.modal.show({
                message: 'Deseja remover os ficheiros selecionados?',
                okButton: 'Remover ficheiro',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                let del = [];
                this.selectedItems.forEach( (i, k) => {
                    if(i){
                        del.push({id:k})
                    }
                })
                await this.removeFiles(del)
                this.reload();
            }
        },
        modalAdd(){
            let selectedItems = [];
            this.selectedItems.forEach( (i,k) => {
                if(i){
                    selectedItems.push(this.files.data.filter( f => f.id == k)[0])
                } 
            })

            if(this.destination)
                this.$emit('add-files-'+this.destination, selectedItems)
            else
                this.$emit('add-files', selectedItems)

            this.$parent.mediaOn = false
        },
        itemLocations(item, withId = false){
            let locations = [], ids = [];
            item.attachments.forEach( a => {
                if(a.content_language && a.content_language[0].deleted_at == null && !ids.includes(a.content_language[0].content_id)){
                    if(withId)
                        locations.push({name:locations.push(a.content_language[0].name), id:a.content_language[0].content_id})
                    else
                        locations.push(a.content_language[0].name)

                ids.push(a.content_language[0].content_id)
                }
            })
            item.contents_main.forEach( a => {
                if(a.deleted_at == null && !ids.includes(a.content_id)){
                    if(withId)
                        locations.push({name:a.name, id:a.content_id})
                    else
                        locations.push(a.name)

                    ids.push(a.content_id)
                }
            })
            item.contents_alternative.forEach( a => {
                if(a.deleted_at == null && !ids.includes(a.content_id)){
                    if(withId)
                        locations.push({name:a.name, id:a.content_id})
                    else
                        locations.push(a.name)

                    ids.push(a.content_id)
                }
            })

            if(locations.length == 0)
                if(withId)
                        locations.push({name:"Sem Localização", id:null})
                    else
                        locations.push("Sem Localização")
                

            return locations;
        },
        setIconExtension(item){
            let extension = item.name.split(".")[item.name.split(".").length - 1];
            let available = ["svg", "pdf", "apk", "css", "doc", "iso", "js", "ppt", "php", "psd", "sql", "txt", "ttf", "xls", "zip"]
            let groups = []

            for (let i = 0; i < available.length; i++) {
                if(extension.toLowerCase().includes(available[i]) && extension.toLowerCase() != 'json')
                return 'i-'+available[i]
            }

            return 'i-other'
        },
        createDropdownInfo(options, value = false){
            let list = [{
                    label: 'Sem categoria',
                    value: 0,
                    checked: value ? false : true
                }];

            for (let i = 0; i < options.length; i++) {
                if(options[i].value){
                    list.push(options[i])
                }else{
                    list.push(                
                    {
                        label: options[i].name,
                        value: options[i].id,
                        checked: options[i].id == value ? true : false
                    })
                }
            }

            return list
        },
        closeUploadWindow(){
            event.preventDefault();
            event.stopPropagation();

            let allDone = true;

            this.uploadProgress.forEach( up => {
                if(up.prog != 'request-finished' && up.prog != 'request-error'){
                    allDone = false;
                }
            })

            //disable popup closing until upload is finished
            if(!allDone){
                this.toast_type = "warning";
                this.toast_message = "Ficheiros em carregamento. Poderá fechar a janela mais tarde.";
                this.toast_show = true; 
                return;
            }

            this.addFlag = false; 
            this.uploadProgress = []; 
            this.count = 0; 
            this.reload();
        }
    },
    mounted(){
        $bus.$on('file-upload', (data) => {
            let count = 0;
            let prog = {prog:data.progress, files: []}
            prog.files = data.info.map( i => {return { name: i.name, size: (i.size/ (1024 ** 2)).toFixed(3) + 'MB'} })
            this.uploadProgress[data.nr] = prog

            //if success show success toast
            if(prog.prog == 'request-finished'){
                this.toast_type = "success";
                this.toast_message = "Todos os ficheiros carregados.";
                this.toast_show = true; 
            }

            //if error show error toast
            if(prog.prog == 'request-error'){
                this.toast_type = "error";
                this.toast_message = "Falha ao carregar ficheiro. Se o problema persistir contacte um administrador.";
                this.toast_show = true; 
            }
        })

        $bus.$on('modal', (showModal) => {
            this.toast_type = "error";

            if(showModal == 'tiff'){
                this.toast_message = "Ficheiros com a extensão TIFF não permitidos.";
            }else if(showModal == 2){
                this.toast_message = "Ficheiro ultrapassa o limite máximo de 2MB.";
            }else if(showModal == 8){
                this.toast_message = "Ficheiro ultrapassa o limite máximo de 8MB.";
            }else if(showModal == 25){
                this.toast_message = "Ficheiro ultrapassa o limite máximo de 25MB.";
            }else{
                return
            }

            this.toast_show = true; 
        })
    }
}
</script>

<style scoped lang="scss">
    @import "../../../../styles/components/layout/media.scss";   
</style>