import _ from "lodash";
import Api from "../api";
import store from "../../../store";

class Templates extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/templates";
        this.requiresAuth = true;
    }

    getTemplates(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    getTemplate(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    createTemplate(data) {
        let url = this.uri;
        return this.post(url, data, this.requiresAuth);
    }

    updateTemplate(params, data, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.put(url, data, this.requiresAuth);
    }

    deleteTemplate(id) {
        return this.delete(this.uri + "/" + id, {}, this.requiresAuth);
    }

}

export default new Templates();
