<template lang="">
    <div>
        <fyi-textarea
            v-bind:value="body"
            @input="body = $event"
            :toolbar="'full'"
        />
        <div style="width:400px; border: 1px solid #000000; border-radius: 8px; margin: 40px auto; padding: 40px">
            <label>Create User Demo</label>
            <fyi-text
                style="margin: 10px auto"
                v-bind:value="name"
                @input-text="name = $event"
                :className="'name'"
                :alias="'name'"
                :label="'Nome'"
                :placeholder="'Insira o nome do novo utilizador'"
                :disabled="false"
                :required="true"
                :inputError="false"
                @submit="userCreateDemo()" />
            <fyi-email
                style="margin: 5px auto;"
                v-bind:value="email"
                @input-email="email = $event"
                @email-valid="email_valid = $event"
                :alias="'email'"
                :label="'E-mail'"
                :placeholder="'Insira o e-mail do novo utilizador'"
                :disabled="false"
                :required="true"
                :inputError="false"
                @submit="userCreateDemo()"
            />
            <fyi-button
                style="margin-top:30px"
                :label="'Criar'"
                :className="'default'"
                :loading="submitStatus=='PENDING'"
                :disabled="name == '' || email == '' || email_valid == false"
                :onclick="() => {userCreateDemo()}"
            />
            <label style="padding-top:30px" v-if="errorMessage != ''" class="error-label">
				{{errorMessage}}
			</label>
        </div>
        <fyi-button
            style="margin-top:30px; width: 300px; margin: auto;"
            :label="'Logout'"
            :className="'default'"
            :loading="submitStatus=='PENDING'"
            :onclick="() => {out()}"
        />
    </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Contents",
  data: function () {
    return {
      body: "",
      email: "",
      name: "",
      email_valid: false,
      errorMessage: "",
      submitStatus: "READY"
    };
  },
  watch: {
    body(_new, _old) {
      console.log(_new);
    },
    email(_new, _old) {
      console.log(_new);
    },
    name(_new, _old) {
      console.log(_new);
    },
    email_valid(_new, _old) {
      console.log(_new);
    },
  },
  methods: {
    ...mapActions(["createUser", "logout"]),
      userCreateDemo() {
          this.submitStatus = "PENDING"
        this.createUser({
          name: this.name,
          email: this.email,
          phone: "",
          apps: [1],
          roles: [1],
          fields: [],
          state: 2,
        }).then( () => {
            this.submitStatus = "READY"
            this.name = ""
            this.email = ""
        }).catch( (err) => {
            this.errorMessage = err;
            this.submitStatus = "ERROR"
        });
    },
    out(){
        this.submitStatus = "PENDING"
        this.logout().then(() => {
            this.$router.push({ name: "login" });
        })
    }
  },
  async beforeMount() {},
  mounted() {},
};
</script>
<style lang="">
</style>