import _ from "lodash";
import Api from "../api";

class Task extends Api {
    constructor() {
        super();
        this.uri = "users/worklog/tasks";
        this.requiresAuth = true;
    }

    all(params) {
        return this.get(this.uri + (params ? "?" + params : ""));
    }

    show(id) {
        return this.get(this.uri + "/" + id);
    }

    create(data) {
        return this.post(this.uri, data);
    }

    remove(id) {
        return this.delete(this.uri + "/" + id);
    }

    edit(id, data) {
        return this.put(this.uri + "/" + id, data);
    }

    import(data) {
        return this.post(this.uri + "/import", data);
    }

    archive(id) {
        return this.patch(this.uri + "/" + id + "/archive");
    }

    restore(id) {
        return this.patch(this.uri + "/" + id + "/restore");
    }
}

export default new Task();