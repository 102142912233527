import Task from "../../../libs/api/worklog/task";

export default {
    state: {
        tasks: [],
    },

    getters: {
        tasks: state => state.tasks,
    },

    mutations: {
        setTasks: (state, tasks) => {
            state.tasks = tasks;
        },
    },

    actions: {
        getTasks: async ({
            commit
        }, params) => {
            const response = await Task.all(params);
            commit("setTasks", response);
            return response
        },
        deleteTask: async ({}, id) => {
            await Task.remove(id);
        },
        createTask: async ({}, data) => {
            await Task.create(data);
        },
        getTask: async ({}, id) => {
            return await Task.show(id);
        },
        editTask: async ({}, {
            id,
            data
        }) => {
            await Task.edit(id, data);
        },
        importTasks: async ({}, data) => {
            const response = await Task.import(data);
            return response
        },
        archiveTask: async ({}, id) => {
            await Task.archive(id);
        },
        restoreTask: async ({}, id) => {
            await Task.restore(id);
        },
    }
};