<template>
    <div class="fyi-button">
        <button v-on:click="onclick" :disabled="disabled" :class="{[className]: true, loading}">
            <fyi-loading v-if="loading" :size="'small'"/>
            <div class="button-inner">
                <i v-if="icon != false" :class="icon"></i>
                <span v-if="showName && !loading"><slot /><label>{{label}}</label></span>
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: 'fyi-button',
    props: {
        onclick: Function,
        label: String,
        showName: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: ""
        },
        icon: {
            type: [Boolean, String],
            default: false
        }

    }
}
</script>

<style lang="scss">
@import "@/styles/components/form/button";
</style>
