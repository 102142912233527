import Apps from '../../libs/api/apps'
import Init from '../../libs/api/init'

export default {
  state: {
    app: "backoffice",
    appConfig: null,
    cmsApp: localStorage.getItem('cmsApp') || null,
    apps: null,
    backofficeAppId: process.env.VUE_APP_BACKOFFICE_ID
  },

  getters: {
    app: state => state.cmsApp ? state.cmsApp : state.app,
    cmsApp: state => state.cmsApp,
    apps: state => state.apps,
    backofficeAppId: state => state.backofficeAppId,
    appConfig: state => state.appConfig,
    getAppsByType: state => type =>
      state.apps === null ? [] : state.apps.filter(app => app.type.alias === type)
  },

  mutations: {
    setApp: (state, app) => {
      state.app = app
    },
    setCmsApp: (state, app) => {
      state.cmsApp = app;

      if (app !== null)
        localStorage.setItem('cmsApp', app)
      else
        localStorage.removeItem('cmsApp')
    },
    setAppConfig: (state, appConfig) => {
      state.appConfig = appConfig
    },
    setApps: (state, apps) => {
      if (apps === null) {
        state.apps = null;
      } else {
        state.apps = apps;
        
        //backoffice app id
        let boToken = process.env.VUE_APP_BACKOFFICE_TOKEN || "backoffice";
        let backofficeIndex = apps.findIndex(a => a.token === boToken);
        if(apps[backofficeIndex]) {
          state.backofficeAppId = apps[backofficeIndex].id;
        } 
      }
    }
  },

  actions: {
    getApps: async ({ commit }) => {
      const data = await Apps.getApps()
      commit('setApps', data)

      return data;
    },
    setApp: async ({ commit, state, rootState }, app) => {
      commit('setApp', app)
      if(!state.apps) {
        let data = [];
        if (!!rootState.auth.token || !!rootState.init.clientToken)
          data = await Apps.getApps()
        
        commit('setApps', data)
      }
      app = state.apps.find(a => a.token === app);
      
      let config = {};
      if (app && app.config != undefined && app.config != null)
        config = app.config;

      commit('setAppConfig', config)
    },
    setCmsApp: ({ commit }, app) => {
      commit('setCmsApp', app)
      let config = {}
      if (app && app.config != undefined && app.config != null) {
		  config = app.config;
		  commit('setAppConfig', config)
	  }

    },
    setAppConfig: ({ commit }, app) => {
      commit('setAppConfig', app)
    },
  }
}
