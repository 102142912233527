<template>
    <div class="field edit" v-if="field != null" :class="[{'open' : open}]">

        <SimpleSelect 
        :label="'Tipo'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="field.type = $event" :options="fieldTypes.map( t => { return {label: t.label, value: t.value, checked: field.type == t.value}})"
        />

        <fyi-text
            v-bind:value="field.languages[0].label"
            v-on:input-text="field.languages[0].label = $event"
            :label="'Nome'"
            :icon_box="false" :full="true"
        />

        <fyi-textarea
            v-bind:value="field.languages[0].data"
            v-on:input="field.languages[0].data = $event"
            :label="'Data'"
            :toolbar="'none'"
        />
<!-- field types: attachment, body, checkbox, date, description, dropdown, email, file, label, name, phone, privacy, radio, select, text-->
        <div class="field-edit">
            <div class="collapsible" @click="open($event)">
                <label>Tipos de Conteúdo Associados</label>
                <i class="i-collapse"/>
            </div>
            <div class="types-available-section">
                <Search
                    @done-search="searchTypes($event)"
                    :placeholder="$t('fast_search')"
                />
                <div class="types-available" ref="types_available">
                    <fyi-checkbox v-for="(type, k) in typesModel" :alias="type.languages[0].alias" :label="type.languages[0].name"    
                    :value="field.types.includes(type.id)"  
                    :checked="field.types.includes(type.id)"                      
                    v-on:input="handleTypes( type.id, $event.checked)" :key="k"/> 
                </div>
            </div>
        </div>
        <fyi-button
            :label="'Guardar'"
            :className="'default save'"
            :loading="submitStatus=='PENDING'"
            :onclick="() => save()"
        />
    </div>
    <teleport to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
    </teleport>

</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"FieldsEdit",
    data(){
        return{
            fieldTypes: [{
                label: "Anexo",
                value: "attachment",
                checked: false
            },
            {
                label: "Corpo",
                value: "body",
                checked: false
            },
            {
                label: "Data",
                value: "date",
                checked: false
            },
            {
                label: "Descrição",
                value: "description",
                checked: false
            },{
                label: "Dropdown",
                value: "dropdown",
                checked: false
            },{
                label: "Email",
                value: "email",
                checked: false
            },{
                label: "Ficheiro",
                value: "file",
                checked: false
            },{
                label: "Label",
                value: "label",
                checked: false
            },{
                label: "Nome",
                value: "name",
                checked: false
            },{
                label: "Telefone",
                value: "phone",
                checked: false
            },{
                label: "Privacidade",
                value: "privacy",
                checked: false
            },{
                label: "Radio",
                value: "description",
                checked: false
            },{
                label: "Select",
                value: "select",
                checked: false
            },{
                label: "Texto",
                value: "text",
                checked: false
            },
            {
                label: "Toggle",
                value: "toggle",
                checked: false
            },
            {
                label: "URL",
                value: "url",
                checked: false
            },],          
            types: [],
            submitStatus:"READY",
            typesModel:[],
            field: null,
            contents: [],
            pagesParsed: [],
            toast_type: '',
            toast_message: '',
            toast_show: false,
            addPage: false,
            pageTemp: {},
            addPageContent: [],
            addPageContentKey: 1,
            loadTable: false,
            newPosition: ''
        }
    },
    async beforeMount(){
        if(this.$route.name.includes("edit")){
            this.field = await this.getField({id: this.$route.params.field_id})
        }else{
            this.field = {
                        type: '',
                        languages: [{
                            label:'',
                            data:'',
                        }],
                        required:0,
                        orderfield:0,
                        types: [],
                    }
        }
        this.typesModel = await this.getTypes();

        this.$parent.$parent.loading = false;
        this.$parent.$parent.transition = false;
    },
    methods:{
        ...mapActions(["getField", "getTypes", "createField", "updateField"]),
        hasPermission(prmsion) {
            let permissions = this.$store.getters.auth.permissions
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(prmsion.some(item => item == p.split(".")[0])){
                        flag++;
                    }
                });

                return flag == prmsion.length
            }
        },
        open(ev){
            ev.target.parentElement.classList.toggle('open')
        },
        handleTypes(id, add){
            if(add){
                this.field.types.push(id)
            }else{
                this.field.types.splice( this.field.types.indexOf(id) ,1)
            }
        },
        searchTypes(value){
            this.$refs.types_available.querySelectorAll('div label').forEach( i => {
                    i.parentElement.classList.remove('hidden')
                }
            );

            this.$refs.types_available.querySelectorAll('div label').forEach( i => {
                if(i.innerHTML.toLowerCase().includes(value.toLowerCase())){
                    i.parentElement.classList.remove('hidden')
                }else{
                    i.parentElement.classList.add('hidden')
                }
            })
        },
        async save(){
            try{
                this.submitStatus = "PENDING"
                let field = this.field

                field.languages[0] = {
                    language_id: 1,
                    label: field.languages[0].label,
                    data: field.languages[0].data.replace(/<\/?[^>]+(>|$)/g, "")
                }

                if(this.$route.name.includes("edit"))
                    await this.updateField({data: field, id: this.field.id})
                else{
                    await this.createField({data: field})
                    this.$router.push({ name: 'fields', params: { id: this.$route.params.id }})
                }

                this.submitStatus = "READY"
                this.toast_type = "success";
                this.toast_message = "Alterado com sucesso";
                this.toast_show = true;
            }catch(error){
                console.log(error)
                this.toast_type = "error";
                this.toast_message = "Aconteceu um erro. Tente novamente.";
                this.toast_show = true;
            }

        },
    },
    mounted(){
        if(this.$route.name.includes("edit"))
            this.$parent.$parent.view = this.$t('edit')
        else
            this.$parent.$parent.view = this.$t('Criar')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/fields.scss";   
</style>