<!-- https://loading.io/css/ -->

<template>
    <div class="fyi-loading" v-bind:style="[getSizeStyle()]"></div>
</template>

<script>
export default {
    name: 'fyi-loading',
    props: {
        size: {
            type: String,
            default: 'small'
        }
    },
    data() {
        return  {
            smallStyle: {
                width: '22px',
                height: '22px',
                ['border-width']: '2px',
            },
            mediumStyle: {
                width: '60px',
                height: '60px',
                ['border-width']: '4px',
            },
            bigStyle: {
                width: '120px',
                height: '120px',
                ['border-width']: '8px',
            }
        }
    },
    methods: {
        getSizeStyle() {
            if (this.size === 'big') return this.bigStyle
            else if (this.size === 'medium') return this.mediumStyle
            else return this.smallStyle
        }
    }
}
</script>

<style lang="scss">
.fyi-loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3E4CD3; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
