<template>
    <label class="form-title">{{$t('users')}}</label>
    <div id="content-form" class="content-form" v-if="user != null || $route.params.id == null">
        <div class="form">
            <!--Base content!-->
            <fyi-text
                v-bind:value="user ? user.name : ''"
                @input-text="user.name = $event"
                :label="'Nome'"
                :required="true"
                :inputError="true"
                :icon_box="false" :full="true"/>
            <fyi-text
                v-bind:value="user ? user.email : ''"
                @input-text="user.email = $event"
                :label="'Email'"
                :icon_box="false" :full="true"/>
            <fyi-phone
                v-bind:value="user ? user.phone : ''"
                :label="'Telemóvel'"
                @input-phone="user.phone = $event"
            />
        </div>
        <div class="options-sidebar">
            <label class="title">{{this.$route.params.id ? 'Editar' : 'Criar'}}</label>
            <div class="buttons">
                <span v-if="$route.name.includes('edit')" :onclick="deleteUser">Eliminar</span>
                <fyi-button
                    :label="($route.name.includes('edit') ? 'Atualizar' : 'Finalizar')"
                    :className="'default ' + ($route.name.includes('edit') ? 'update' : 'create')"
                    :onclick="save"
                />
            </div>
            <div class="section access" ref="access" >
                <div class="bar" @click="openBar('access')">
                    <i class="i-collapse"/>
                    <label>Acessos</label>
                </div>
                <div class="options">
                    <InputTags
                        v-model="app"
                        :tags="userApps"
                        :label="'Aplicações'"
                        :disabled="false"
                        :icon_box="false" :full="true"
                        :autocompleteItems="filteredApps"
                        :noUrlRedirect="true"
                        :onlyAutocomplete="true"
                        @input-tags="userApps = $event"
                    />
                    <InputTags
                        v-model="role"
                        :tags="userRoles"
                        :label="'Roles'"
                        :disabled="false"
                        :noUrlRedirect="true"
                        :icon_box="false" :full="true"
                        :autocompleteItems="filteredRoles"
                        :onlyAutocomplete="true"
                        @input-tags="userRoles = $event"
                    />
                </div>
            </div>
        </div>
        <div onclick="this.previousElementSibling.classList.toggle('open')" class="sidebar-mobile-tab">
            <i class="i-collapse"></i>
        </div>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
        <teleport to="body">
            <transition name="fade">
                <LoadingPage v-if="transition" />
            </transition>
        </teleport>
    </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
    name:"Users",
    data(){
        return {
            role: '',
            app: '',
            user: null,
            roles:[],
            apps:[],
            userRoles: [],
            userApps: [],
            loading: true,
            transition: true
        }
    },
    async beforeMount(){
        if(this.$route.params.id){
            this.user = await this.getUser(this.$route.params.id)
            this.userRoles = this.user.roles.map( r => {{return {text: r.name, id:r.id}}})
            this.userApps = this.user.apps.map( a => {{return {text: a.name, id:a.id}}})
        }else
            this.user = {
                name: '',
                email: '',
                phone: '',
                apps: [],
                roles: [],
                fields: [],
                state: 2,
            }
        this.roles = await this.getRoles()
        this.apps = await this.getApps()
        this.loading = false;
        this.transition = false;
    },
    computed: {
        filteredRoles() {
            let array = this.roles.map( r => {return {text: r.name, id:r.id}}).filter(i => {
                return i.text.toLowerCase().indexOf(this.role.toLowerCase()) !== -1;
            });
            return array;
        },
        filteredApps() {
            return this.apps.map( r => {return {text: r.name, id:r.id}}).filter(i => {
                return i.text.toLowerCase().indexOf(this.app.toLowerCase()) !== -1;
            });
        },
    },
    watch:{

    },
    methods: {
        ...mapActions(["getUser", "getRoles", "getApps", "editUser", "createUser"]),
        openBar(element){
            document.querySelectorAll(".section.active").forEach( el => {if(!el.classList.contains(element)) el.classList.remove("active")})
            this.$refs[element].classList.toggle('active')
        },
        save(){
            this.user.roles = []
            this.userRoles.forEach( r => {
                this.user.roles.push(r.id)
            })
            this.user.apps = []
            this.userApps.forEach( a => {
                this.user.apps.push(a.id)
            })
            
            if(this.$route.params.id){
                this.editUser({id: parseInt(this.$route.params.id), data: this.user }).then(() => {
                    this.$router.push({ name: "users" });
                })
            }else{
                this.createUser(this.user).then(() => {
                    this.$router.push({ name: "users" });
                })
            }
        },
        async deleteUser(){
            const ok = await this.$refs.modal.show({
                message: "Deseja eliminar o utilizador?",
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deleteUser(this.$route.params.id).then(() => {
                    this.$router.push({ name: "dashboard_users" });
                })
            }
        }
    },
}
</script>


<style lang="scss" scoped>
    @import "../../styles/pages/users.scss";   
</style>