import _ from "lodash";
import Api from "../api";

class Metadatas extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/metadatas";
        this.requiresAuth = true;
    }

    getMetadata(id) {
        return this.get(this.uri + "/" + id, {}, this.requiresAuth);
    }
    
    getMetadatas(params) {
        return this.get(this.uri + "?" + params, {}, this.requiresAuth);
    }
    
    create(params) {
        return this.post(this.uri, params, this.requiresAuth);
    }
    
    deleteMetadata(id) {
        return this.delete(this.uri + "/" + id, {}, this.requiresAuth);
    }
   
    edit(id, params) {
        return this.put(this.uri + "/" + id, params, this.requiresAuth);
    }
}

export default new Metadatas();
