<template>
    <div class="upload">
        <label>{{field.field.languages[0].label}}</label>
        <fyi-text
            v-bind:value="field.value"
            :label="''"
            :placeholder="'Sem ficheiro'"
            :disabled="true"
            :required="field.field.required == 1"
            :icon_box="false"
        />
        <fyi-button
            :icon="'far fa-eye ' + (field.value != '' ? 'file'  : 'available')"
            :className="'default preview-bt'"
            :onclick="() =>{if(field.value != '') previewFile()}"
        />
        <fyi-button
            :icon="'i-upload ' + (field.value != '' ? 'file'  : 'available')"
            :className="'default upload-bt'"
            :onclick="() => mediaOn = true"
        />
        <fyi-button
            :icon="'i-remove ' + (field.value != '' ? 'file'  : 'available')"
            :className="'default remove-bt'"
            :onclick="() => {if(field.value != '')$emit('upload', '')}"
        />
        <teleport to="body">
            <div v-if="mediaOn" class="modal-media" style="z-index: 1302">
                <Media :modal="true" :mime="mime" :multiSelect="false" @add-files="addFiles"/>
            </div>
        </teleport>
    </div>
</template>

<script>

export default {
    name:"Upload",
    props: {
        field:{
            type: Object,
            default: {}
        },
        mime:{
            type: String,
            default: ''
        },
    },
    data(){
        return {
            mediaOn: false
        }
    },
    methods: {
        previewFile(){
            window.open(this.$store.getters.appConfig.url + "/" + this.field.value, "_blank")
        },
        addFiles(files){
            this.$emit('upload', files[0].path)
        },
    }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/components/form/upload.scss";
</style>