<template>
	<div class="fyi-input icon-box password">
		<label>{{ label }}</label>
		<input
			name="password"
			:type="visibilityType"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
			v-model.trim="value"
			@input="$emit('input-password', value)"
			@keyup.enter="$emit('submit')"
		/>
		<div
			v-if="show"
			@mousedown="handleVisibilityClick"
			@mouseup="handleVisibilityClick"
			@touchstart="handleVisibilityClick"
			@touchend="handleVisibilityClick"
			class="show-password"
		>
			<i :class="{'far fa-eye': visibility === false, 'far fa-eye-slash': visibility === true,}"></i>
		</div>
		<span v-if="inputError" :class="'error-message ' + errorType"><i v-if="inputError" :class="['error-icon', {'far fa-check-circle': errorType == 'valid'}, {'fas fa-exclamation-circle': errorType == 'warning' || errorType == ''}]"></i>{{ errorMessage }}</span>
	</div>
</template>

<script>
export default {
	name: "fyi-password",
	props: {
		value: String,
		alias: String,
		label: {
			type: String,
			default: ""
		},
		errorMessage: {
			type: String,
			default: "8 caracteres, 1 símbolo, maiúsculas e minúsculas"
		},
		placeholder: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: true
		},
		inputError: {
			type: Boolean,
			default: true
		},
		errorType: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			visibility: false,
			visibilityType: "password"
		};
	},
	mounted() {
		let self = this;
		window.addEventListener("mouseup", function(event) {
			if (self.visibility) {
				self.visibility = false;
			}
			self.visibilityType = self.visibility ? "text" : "password";
		});
	},
	methods: {
		handleVisibilityClick() {
			this.visibility = !this.visibility;
			this.visibilityType = this.visibility ? "text" : "password";
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/input";
</style>
