import Languages from '../../locales';
const supportedLanguages = Object.getOwnPropertyNames(Languages);

export default {
  state: {
    language: localStorage.getItem('language') || 'pt'
  },

  getters: {
    language: state => state
  },

  mutations: {
    setLanguage: (state, lang) => {
        localStorage.setItem('language', lang)
        state.language = lang;
    }
  },

  actions: {
    setLanguage({ commit }, languages) {
        if (typeof languages === 'string') {
          commit('setLanguage', languages);
        } else {
            const language = supportedLanguages.find(sl =>
                languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null))
            );
            commit('setLanguage', language);
        }
      }
  }
}
