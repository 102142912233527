<template>
    <div v-if="parsedFields.length > 0" class="addon fields" :class="[{'open' : open}]"> 
        <div class="collapsible" @click="open = !open">
            <label>Campos extra</label>
            <i class="i-collapse" />
        </div>
        <div class="fields-form">
            <template v-for="(field, k) in parsedFields" :key="k">
                <fyi-textarea
                    v-if="field.field !=undefined && field.field.type === 'textarea'"
                    v-bind:value="field.value"
                    v-on:input="field.value = $event"
                    :label="field.field.languages[0].label"
                    :placeholder="field.field.placeholder"
                    :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1"
                    :toolbar="'full'"
                />
                <fyi-email
                    v-else-if="field.field !=undefined && field.field.type === 'email'"
					v-bind:value="field.value"
					@input-email="field.value = $event"
					:label="field.field.languages[0].label"
					:placeholder="field.field.placeholder"
                    :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1"
					:inputError="true"
                    :icon_box="false" :full="true"
				/>
                <SimpleSelect 
                    v-else-if="(field.field !=undefined && field.field.type === 'dropdown')"
                    :label="field.field.languages[0].label"
                    @select="field.value = $event" :previewIcon="'i-order'" :options="createDropdownInfo(field.field.languages[0].data ? field.field.languages[0].data : field.field.data, field.field_id)"/>
                <SimpleSelect 
                    v-else-if="(field.field !=undefined && field.field.type === 'select')"
                    :label="field.field.languages[0].label"
                    @select-data-value="field.value = $event" :previewIcon="'i-order'" :options="createDropdownInfo(field.field.languages[0].data ? field.field.languages[0].data : field.field.data, field.field_id)"/>
                <Upload v-else-if="field.field !=undefined && field.field.type === 'file'" :field="field" @upload="field.value = $event"/>
                <fyi-phone
                    v-else-if="field.field !=undefined && field.field.type === 'phone'"
                    v-bind:value="field.value"
                    :label="field.field.languages[0].label"
                    :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1"
                    @input-phone="field.value = $event"
                />
                <fyi-checkbox 
                    v-else-if="field.field !=undefined && field.field.type === 'checkbox'"
                    :label="field.field.languages[0].label"    
					:value="field.value"  
					:checked="field.value"                      
					v-on:input="field.value = $event.checked"
					/>
                <fyi-radio
                    v-else-if="field.field !=undefined && field.field.type === 'radio'"
                    v-bind:value="field.value"
                    v-on:input="field.value = $event"
                    :label="field.field.languages[0].label"
                    :options="createDropdownInfo(field.field.languages[0].data ? field.field.languages[0].data : field.field.data, field.field_id)"
                    :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1"
                    @input="field.value = $event"
                />
                <Switch v-else-if="field.field !=undefined && field.field.type === 'toggle'" :enabled="field.value == 1" :showLabel="true" :label="field.field.languages[0].label" 
                @input="field.value = $event == 1 ? true : false"/>
                <fyi-text
                    v-else-if="field.field !=undefined"
                    v-bind:value="field.value"
                    v-on:input-text="field.value = $event"
                    :label="field.field.languages[0].label"
                    :placeholder="field.field.placeholder"
                    :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1"
                    :icon_box="false" :full="true"
                />
            </template>
        </div>
    </div>
            <!--
email
password
text
specific-event (?)
phone
datepicker
timepicker
schedule
url
toggle (switch)
textarea
radio
checkbox
select
table
relate-contents
upload
            -->
</template>

<script>
export default {
    name:"AddonFields",
    props: {
        content: {
            type: Object,
            default: null
        }
    },
    watch: {
        parsedFields:{
            handler: function(_new, _old){
                this.$emit('update-fields', _new)
            },
            deep: true
        },
    },
    computed:{
        parsedFields(){
            let fields = this.content.type.fields;

            fields.forEach(field => {
                let value = this.content.fields.filter( f => f.id == field.field_id)
                if(value.length > 0)
                    field.value = value[0].pivot.value
                else
                    field.value = ''
            });

            return fields
        }
    },
    data(){
        return{
            open: false,
        }
    },
    methods: {
        createDropdownInfo(data, field_id){
            let options = JSON.parse(data);
            let list = [];
            let selected = null;

            this.content.fields.forEach( f => {
                if(f.id == field_id){
                    selected = f.pivot.value;
                }
            })

            for (let i = 0; i < options.length; i++) {
                if(options[i].value != undefined)
                    list.push(                
                    {
                        label: options[i].label,
                        value: options[i].value,
                        checked: options[i].value == selected
                    })
                else
                    list.push(                
                    {
                        label: options[i],
                        value: i,
                        checked: options[i] == selected
                    })
            }
            return list
        }
    },
    mounted(){
        this.$emit('update-fields', this.parsedFields)
    }
}
</script>
    
<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>