<template>
    <div class="pages">
        <div class="page">
            <div class="type-sidebar" v-if="!$route.query.filter || !isNaN($route.query.filter)">
                <TypesTree ref="sidebar" :node="levels" :level="1" @current_type="content_state='both';filterOn=false;curType=$event"/>
            </div>
            <div onclick="this.previousElementSibling.classList.toggle('open')" class="sidebar-mobile-tab">
                <i class="i-collapse"></i>
            </div>
            <div class="page-content" :class="[{'full': $route.query.filter && isNaN($route.query.filter)}]">
                <Table ref="table" :items="content" :paginationLoading="paginationLoading" :loading="loadTable" :limit="limit"
                :availableOrders="availableOrders" :availableDirections="availableDirections" :availableFilters="availableFilters" :availableStates="availableStates"
                @handlePagination="handlePagination" @apply-order="updateOrder" @apply="updateFilter($event)" @filter-type="updateFilter({value:$event})"
                @search="searchValue = $event" :availableEntries="availableEntries" @entry-select="setLimit($event)" :contextMenu="contextMenu"
                @preview="previewItem" @remove="removeItem" @toggleSwitch="toggleSwitch" @edit="editItem"/>
            </div>
            <teleport to="body">
                <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
            </teleport>
            <teleport to="body">
                <Modal ref="modal" />
            </teleport>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TypesTree from "./helper/TypesTree.vue"

export default {
    name:"Pages",
    components: {
        TypesTree
    },
    data() {
        return {
            loading: true,
            limit: 9,
            limitTimeout: null,
            limitOnResize: true,
            types: [],
            typesParsed: [],
            curType: '',
            order: 'date_desc',
            content_state: 'both',
            content: [],
            availableFilters: [],
            availableOrders: [
                {
                    alias:"type",
                    label:this.$t('location'),
                    checked: false
                },
                {
                    alias:"name",
                    label:this.$t('name'),
                    checked: false
                },
                {
                    alias:"date",
                    label:this.$t('date_created'),
                    checked: true
                },
                {
                    alias:"update",
                    label:this.$t('date_updated'),
                    checked: false
                },
            ],
            availableDirections:[
                {
                    alias:"asc",
                    label:this.$t('asc'),
                    checked: false
                },
                {
                    alias:"desc",
                    label:this.$t('desc'),
                    checked: true
                },
            ],
            availableStates: [
                {
                    label: this.$t('published'),
                    alias: "published",
                    checked: false
                },
                {
                    label: this.$t('unpublished'),
                    alias: "unpublished",
                    checked: false
                },
                {
                    label: this.$t('draft'),
                    alias: "draft",
                    checked: false
                },
            ],
            availableEntries:[
                {
                    label: "Default",
                    value: "default",
                    checked: true
                },
                {
                    label: "20",
                    value: "20",
                    checked: false
                },
                {
                    label: "50",
                    value: "50",
                    checked: false
                },
                {
                    label: "100",
                    value: "100",
                    checked: false
                },
            ],
            contextMenu: [
                {label:this.$t('add_el'), class:"i-plus", func: this.addItem},
                {label:this.$t('export_table'), class:"i-export", func: this.exportTable},
                {label:this.$t('clear_cache'), class:"i-cache", func: this.cacheClear},
                {label:this.$t('disable_el'), class:"i-unpublish", func: this.unpublishItems},
                {label:this.$t('delete_el'), class:"i-remove", func: this.removeItems},
            ],
            loadTable: false,
            paginationLoading: false,
            filterOn: false,
            searchValue: "",
            toast_type: '',
            toast_message: '',
            toast_show: false
        }
    },
    created() {
        window.addEventListener("resize", this.changeLimit);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeLimit);
    },
    async beforeMount(){
        this.changeLimit();
        this.types = await this.getTypes();
        if(this.$route.query.filter != undefined){
            if(isNaN(this.$route.query.filter)){
                this.types.forEach( t => {
                    if(t.languages[0].alias == this.$route.query.filter){
                        this.curType = t.id + ""; 
                    }
                })
            }else{
                this.curType = this.$route.query.filter; 
            }
            setTimeout(() => {
                if(document.querySelector(".filter li[value='"+this.$route.query.filter+"']"))document.querySelector(".filter li[value='"+this.$route.query.filter+"']").classList.add("active")
                this.$refs.table.$refs.filter.selected.push(this.$route.query.filter)
            }, 1000)
        }else{
            this.content = await this.getContents('noaddons=true&limit='+this.limit);
            this.availableFilters = this.parseFilters();
        }
        this.$parent.$parent.transition = false;
    },
    watch: {
        async curType(_new,_old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&types='+_new);

            if(!this.filterOn)
                this.availableFilters = this.parseFilters();

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async order(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+_new+'&types='+this.curType);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async content_state(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&types='+this.curType);

            if(!this.filterOn)
                this.availableFilters = this.parseFilters();

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async searchValue(_new, _old){
            if(_new.trim() == "" && _old == '' )
                return

            await this.search()
        }
    },
    computed: {
        //group every type by level for the sidebar
        levels(){
            const _this = this
            let levels = {children: [], languages:{name:""}};
            let ids = this.types.map(item => item.id);
            this.types.forEach( type => {
                if(type.config != null && type.config.show != undefined && type.config.show == false){
                    //DO NOTHING
                }else{
                    if(!type.parent || type.parent === 0){
                        levels.children.push(type);
                        //filter current language as the default
                        let languages = levels.children[levels.children.length - 1].languages.filter( l => {
                            return l.language != null && l.language.locale.includes(_this.$i18n.locale)
                        } )
                        
                        //if current app language doesnt exist grab the first one available
                        if(languages.length != 0)
                            levels.children[levels.children.length - 1].languages = languages[0]
                        else
                            levels.children[levels.children.length - 1].languages = levels.children[levels.children.length - 1].languages[0]
    
                        //get all type's children recursively
                        levels.children[levels.children.length - 1].children = this.getChildren(type.id)
                            //sort
                        levels.children[levels.children.length - 1].children.sort( (a,b) => {
                            return (a.position >= b.position) ? 1 : -1
                        })
                        //get all types associated recursively (current and child types)
                        levels.children[levels.children.length - 1].associated_types = this.getAssociatedTypes(levels.children[levels.children.length - 1]);
                    //if parent not available (permissions)    
                    }else if(!ids.includes(type.parent)){
                        levels.children.push(type);
                        //filter current language as the default
                        let languages = levels.children[levels.children.length - 1].languages.filter( l => {
                            return l.language != null && l.language.locale.includes(_this.$i18n.locale)
                        } )
                        
                        //if current app language doesnt exist grab the first one available
                        if(languages.length != 0)
                            levels.children[levels.children.length - 1].languages = languages[0]
                        else
                            levels.children[levels.children.length - 1].languages = levels.children[levels.children.length - 1].languages[0]
    
                        //get all type's children recursively
                        levels.children[levels.children.length - 1].children = this.getChildren(type.id)
                        //sort
                        levels.children[levels.children.length - 1].children.sort( (a,b) => {
                            return (a.position >= b.position) ? 1 : -1
                        })
                        //get all types associated recursively (current and child types)
                        levels.children[levels.children.length - 1].associated_types = this.getAssociatedTypes(levels.children[levels.children.length - 1]);
                    }
                }
            })
            
            levels.children.sort( (a,b) => {
                return (a.position >= b.position) ? 1 : -1
            })

            return levels
        }
    },
    methods: {
        ...mapActions(["getTypes", "getContents", "deleteBatchContents", "togglePublished", "searchContents", "exportContents", "batchContents"]),
        //recursive function for the levels computed prop (has the same functionality)
        getChildren(id){
            const _this = this
            
            let items = []

            for (let i = 0; i < this.types.length; i++) {
                if(this.types[i].config != null && this.types[i].config.show != undefined && this.types[i].config.show == false)
                    continue

                if(this.types[i].parent == id){
                    items.push(this.types[i]);

                    let languages = items[items.length - 1].languages.filter( l => {
                        return l.language != null && l.language.locale.includes(_this.$i18n.locale)
                    } )

                    if(languages.length != 0)
                        items[items.length - 1].languages = languages[0]
                    else
                        items[items.length - 1].languages = items[items.length - 1].languages[0]

                    items[items.length - 1].children = this.getChildren(this.types[i].id)
                    items[items.length - 1].associated_types = this.getAssociatedTypes(items[items.length - 1]);
                }
            }

            return items
        },
        getAssociatedTypes(item){
            let types = item.id + ",";

            item.children.forEach( c => {
                types += this.getAssociatedTypes(c, false) + ","
            })

            types = types.includes(",") ? types.slice(0, -1) : types

            return types
        },
        //get all available filters for current selected type ( only current and below levels are considered)
        parseFilters(){
            let filters = [];
            this.$refs.table.$refs.filter.restore(false)
            let search = this.curType.split(",")
            this.types.forEach( t => {
                if(t.config != null && t.config.show != undefined && t.config.show == false){
                    //DO NOTHING
                }else if((this.curType != '' && search.includes(t.id.toString())) || this.curType == ''){
                    let tmp = {};
                    tmp.value = t.id
                    tmp.label = t.languages.name
                    filters.push(tmp)
                }
            })

            return filters;
        },
        //handles pagination requests
        async handlePagination(page){
            //set current table size to maintain gap for the pagination widget so it doesnt move around
            this.$refs.table.$el.querySelector(".pagination").style.top = this.$refs.table.$el.querySelector(".template-table").clientHeight + 47 + "px";

            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            //show loading inside the table element
            this.loadTable = true;

            //get all contents of current selected type
            if(this.curType != '')
                this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&types='+this.curType+'&page='+page)
            else
            //if no type, get all
                this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&page='+page)

            //stop loading widget
            this.loadTable = false;
            this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        previewItem(item){
            window.open(this.$store.getters.appConfig.url + "/" + item.type.languages[0].alias + "/" + item.languages[0].alias, "_blank")
        },
        async removeItem(ids){
            ids = Array.isArray(ids) ? ids.join(",") : ids

            const ok = await this.$refs.modal.show({
                message: 'Deseja remover o conteúdo selecionado?',
                okButton: 'Remover conteúdo',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                await this.deleteBatchContents({contents: ids});
                this.reload()
            }
        }, 
        async editItem(item){
            this.$router.push({ name: "pages_cms_edit", params: { id: this.$route.params.id, content_id: item } })
        },
        async toggleSwitch(data){
            let { toggle, item} = data;
            event.stopPropagation();
            await this.togglePublished({published:toggle, id:item})            
        },
        updateFilter(info){
            if(info !== null){
                let curType = info.ids ? info.ids.join(",") : info.value.toString()

                if( this.$refs.sidebar.curTypes != "" && curType == ""){
                    //DO NOTHING
                }else{
                    this.curType = curType
                    if(info.value){
                        document.querySelector(".filter li[value='"+info.value+"']").classList.add("active")
                        this.$refs.table.$refs.filter.selected.push(info.value)
                    }
                }

                if(info["states"]){
                    let content_state = 'both';

                    for(let k in info["states"][0]){
                        if((info["states"][0][k] && k == "published")){
                            content_state = 1; 
                            break;
                        }

                        if((info["states"][0][k] && k == "unpublished")){
                            content_state = 0; 
                            break;
                        }
                    }

                    this.content_state = content_state;
                    this.filterOn = true;
                }

            }else{
                this.curType = this.$refs.sidebar.curTypes
                this.filterOn = false;
            }
        },
        updateOrder(info){
            let order = ''
            for(let k in info["states"][0])
                if(info["states"][0][k]) order = k+"_"

            for(let k in info["states"][1])
                if(info["states"][1][k]) order += k
            
            this.order = order
        },
        async reload(){
            this.$refs.table.resetPage(1)
            if(this.searchValue){
                this.search()
                return
            }

            this.loadTable = true;
            this.paginationLoading = true;
            this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&types='+this.curType);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async search(page = false){
            this.loadTable = true;
            if(!page){
                this.paginationLoading = true;
                this.$refs.table.resetPage(1)
            }

            if(this.searchValue == '')
                this.content = await this.getContents('noaddons=true&limit='+this.limit+'&published='+this.content_state+"&order="+this.order+'&types='+this.curType);
            else if(page)
                this.content = await this.searchContents({params: "limit="+this.limit+"&order="+this.order+"&types="+this.curType+"&page="+page, data: {text: this.searchValue }});
            else
                this.content = await this.searchContents({params: "limit="+this.limit+"&order="+this.order+"&types="+this.curType, data: {text: this.searchValue }});

            this.loadTable = false;

            if(!page)
                this.paginationLoading = false;

            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        cacheClear(){
            axios.get(this.$store.getters.appConfig.url.replace('https', 'http') + "/cache/clear")
            this.toast_type = "success";
            this.toast_message = "Limpeza de cache bem sucedida";
            this.toast_show = true;
        },
        async exportTable(){
            let types = this.curType
            if(types == "")
                types = this.types.map( type => type.id)

            let { link } = await this.exportContents('type_id='+types+'&text='+this.searchValue);

            let download = document.createElement("a")
            download.style.display = 'none';
            download.download = "export.csv"
            download.href = link;
            document.querySelector("#app").appendChild(download);
            download.click()
            download.remove()
        },
        addItem(){
            if(this.curType == "" || this.curType.split(",").length > 1){
                this.toast_type = "error";
                this.toast_message = "Selecione apenas um tipo para poder criar um novo conteúdo.";
                this.toast_show = true;
                return
            }
            this.$router.push({ name: 'pages_cms_create', query: { id: this.curType }})
        },
        async unpublishItems(){
            const ok = await this.$refs.modal.show({
                message: "Deseja despublicar " + this.content.total + " conteúdo/s?",
                okButton: 'Despublicar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                let types = this.curType
                if(types == "")
                    types = this.types.map( type => type.id)

                this.batchContents({data:{type_id: types, text: this.searchValue}, action: "unpublish"})
                this.reload()
            }
        },
        async removeItems(){
            const ok = await this.$refs.modal.show({
                message: "Deseja remover " + this.content.total + " conteúdo/s?",
                okButton: 'Remover',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                let types = this.curType
                if(types == "")
                    types = this.types.map( type => type.id)

                this.batchContents({data:{type_id: types, text: this.searchValue}, action: "delete"})
                this.reload()
            }
        },
        setLimit(value){
            //handles content limit set on the UI
            if(value != 'Default'){
                this.limitOnResize = false;
                this.limit = parseInt(value);
                if(this.content.data != undefined){
                    this.reload();
                }
            }else{
                this.limitOnResize = true;
                this.changeLimit();
            }
        },
        changeLimit(){
            if(!this.$parent.$parent.$parent.$parent.expired && this.limitOnResize){
                //throttling so it doesnt make the same request while still performing a resize
                clearTimeout(this.limitTimeout);
                this.limitTimeout = setTimeout(() => {
                    let availableHeight = window.innerHeight - 144 - 144 - 88 - 30; // 144 - page header; 144 - table filters; 30 - table header; 88 - pagination element
                    let rows = 0, rowHeight = 60;
                    //while theres still available space increase the number of rows
                    while((availableHeight - rowHeight) > rowHeight){
                        availableHeight -= rowHeight;
                        rowHeight = rowHeight == 60 ? 44 : 60; //row height changes every other row
                        rows++;
                    }
                    this.limit = rows == 0 ? 9 : rows //9 is default number of rows
        
                    //if there is content available reload
                    if(this.content.data != undefined){
                        this.reload();
                    }
                }, 500)
            }
        }
    },
    mounted(){
        this.$parent.$parent.view = this.$t('pages')
    }
}
</script>

<style lang="scss">
    @import "../../../styles/pages/pages.scss";   
</style>