import Calendar from "../../../libs/api/calendar/calendar";

export default {
    state: {
        calendars: []
    },

    getters: {
        calendars: state => state.calendars
    },

    mutations: {
        setCalendars: (state, calendars) => {
            state.calendars = calendars;
        }
    },

    actions: {
        getCalendars: async ({commit}, params) => {
            const response = await Calendar.getCalendars(params);
            commit("setCalendars", response);

            return response;
        }
    }
};