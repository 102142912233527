import Entity from "../../libs/api/entity";

export default {
    state: {
        entities: [],
        projects: [],
    },

    getters: {
        entities: state => state.entities,
        projects: state => state.projects,
    },

    mutations: {
        setEntities: (state, entities) => {
            state.entities = entities;
        },
        setProjects: (state, projects) => {
            state.projects = projects;
        },
    },

    actions: {
        getEntities: async ({
            commit
        }) => {
            const response = await Entity.all();
            commit("setEntities", response);
            return response
        },
        getProjects: async ({
            commit
        }, entity = null) => {
            if (!entity)
                commit("setProjects", []);
            else {
                const response = await Entity.allProjects(entity);
                commit("setProjects", response);
                return response
            }
        },
    }
};