<template>
    <div class="dashboard">
        <CardList
            :title="$t('quick_access')"
            :list="[11,12,14,15,16,17].includes($parent.$parent.app.id) ? menu_cmp : menu"
            :loading='menu.length == 0'
        />
        <div class="grid-column-3 gap-20">
            <Card 
                :title="$t('notifications')"
                :action="$t('remove_all')"
                :list="unreadNot"
                :func="removeNotifications"
                :card_func="removeNotification"
                :className="'notification'"
                :emptyMessage="$t('notifications_empty')"
            />
            <Card 
                :title="$t('stats')"
                :action="$t('view_all')"
                :emptyMessage="$t('stats_empty')"
                :className="'stats'"
                :loading="false"
            />
            <Card 
                :title="$t('environments')"
                :emptyMessage="$t('environment_empty')"
                :list="$parent.$parent.available_apps"
                :className="'environment'"
                :loading="($parent.$parent.available_apps == undefined || Object.keys($parent.$parent.available_apps).length == 0) && loading"
                :card_func="goToEnv"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";


export default {
    name:"DashboardCms",
    data() {
        return {
            menu: [
                {
                    label:this.$t('pages'),
                    icon:"i-pages",
                    route:"pages_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver páginas"
                },
                {
                    label:this.$t('blocks'),
                    icon:"i-blocks",
                    route:"blocks_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: ["blocks"],
                    buttonLabel: "Ver blocos"
                },
                {
                    label:this.$t('news'),
                    icon:"i-news",
                    route: "pages_cms",
                    params: {},
                    query: {filter:'noticias'},
                    permissions: [],
                    buttonLabel: "Ver notícias"
                },
                {
                    label:this.$t('sliders'),
                    icon:"i-sliders",
                    route:"none",//"sliders",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver sliders"
                },
                {
                    label:this.$t('menus'),
                    icon:"i-menus",
                    route:"menus_cms",
                    params: {},
                    query: {},
                    permissions: ["menus"],
                    buttonLabel: "Ver menus"
                },
                {
                    label:this.$t('media'),
                    icon:"i-media",
                    route:"media",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver media"
                },
                {
                    label:this.$t('forms'),
                    icon:"i-forms",
                    route:"forms",
                    params: {},
                    query: {},
                    permissions: ["forms"],
                    buttonLabel: "Ver formulários"
                },
                {
                    label:this.$t('fields'),
                    icon:"i-fields",
                    route:"fields",
                    params: {},
                    query: {},
                    permissions: ["fields"],
                    buttonLabel: "Ver campos extra"
                },
                {
                    label:this.$t('templates'),
                    icon:"i-pages",
                    route:"templates",
                    params: {},
                    query: {},
                    permissions: ["templates"],
                    buttonLabel: "Ver templates"
                },
                {
                    label:this.$t('types'),
                    icon:"i-types",
                    route:"types",
                    params: {},
                    query: {},
                    permissions: ["types"],
                    buttonLabel: "Ver tipos"
                },
            ],
            menu_cmp: [
                {
                    label:this.$t('pages'),
                    icon:"i-pages",
                    route:"pages_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver páginas"
                },
                {
                    label:this.$t('blocks'),
                    icon:"i-blocks",
                    route:"blocks_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: ["blocks"],
                    buttonLabel: "Ver blocos"
                },
                {
                    label:'Destaques',
                    icon:"i-news",
                    route:"pages_cms",
                    params: {},
                    query: {filter:'destaque'},
                    permissions: [],
                    buttonLabel: "Ver destaques"
                },
                {
                    label:this.$t('sliders'),
                    icon:"i-sliders",
                    route:"none",//"sliders",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver sliders"
                },
                {
                    label:this.$t('menus'),
                    icon:"i-menus",
                    route:"menus_cms",
                    params: {},
                    query: {},
                    permissions: ["menus"],
                    buttonLabel: "Ver menus"
                },
                {
                    label:this.$t('media'),
                    icon:"i-media",
                    route:"media",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver media"
                },
                {
                    label:this.$t('forms'),
                    icon:"i-forms",
                    route:"forms",
                    params: {},
                    query: {},
                    permissions: ["forms"],
                    buttonLabel: "Ver formulários"
                },
                {
                    label:this.$t('fields'),
                    icon:"i-fields",
                    route:"fields",
                    params: {},
                    query: {},
                    permissions: ["fields"],
                    buttonLabel: "Ver campos extra"
                },
                {
                    label:this.$t('templates'),
                    icon:"i-pages",
                    route:"templates",
                    params: {},
                    query: {},
                    permissions: ["templates"],
                    buttonLabel: "Ver templates"
                },
                {
                    label:this.$t('types'),
                    icon:"i-types",
                    route:"types",
                    params: {},
                    query: {},
                    permissions: ["types"],
                    buttonLabel: "Ver tipos"
                },
            ],
            loading: true,
        }
    },
    computed: {
        unreadNot(){
            let final = [];
            this.$store.getters.unreadNotifications.forEach(not => {
                if(not.appToken == this.$parent.$parent.app.token){
                    final.push(not)
                }
            });
            return final;
        }
    },
    async beforeMount(){
        await this.getUnreadNotifications("unreadBy="+this.$store.getters.auth.user.id);
        this.loading = false;
        this.$parent.$parent.transition = false;
    },
    methods: {
        ...mapActions(["getUnreadNotifications", "markReadAll", "clearNotifications", "markRead"]),
        removeNotifications(){
            this.markReadAll();
            this.clearNotifications();
        },
        removeNotification(id){
            this.markRead(id);
        },
        goToEnv(id){
            this.$router.push({name:"dashboard_cms", params: {id: id}})
        }
    }, mounted(){
        this.$parent.$parent.view = this.$t('dashboard')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/dashboard.scss";   
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>