<template>
	<div :class="'fyi-input ' + classe">
		<label v-if="label">{{label}}</label>
		<input
			class="input-text"
			type="text"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
            :maxlength="maxlength"
            :onkeypress="'return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) ' + (decimal ? '|| (event.charCode == 46)' : '') + ')' " 
			v-bind:value="value"
            inputmode="numeric"
            @paste="$emit('paste', $event)"
			@keyup="$emit('input-number', $event.target.value)"
            @input="$emit('input-number', $event.target.value)"
            @keypress="$emit('keyhandler', $event)"
            @keydown="$emit('keyhandler', $event)"
            @keyup.enter="$emit('submit')"
		/>
	</div>
</template>

<script>
export default {
    name: "fyi-number",
    props: {
        value: [String, Number],
        classe: String,
        alias: String,
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
		decimal: {
			type: Boolean,
			default: true
		},
        maxlength: {
            type: String,
            default: ""
        },
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/input";
</style>