<template>
    <div class="content">
        <label>{{$t('dashboard')}}</label>
        <CardList
            :title="$t('apps')"
            :list="available_apps"
            :loading='available_apps == undefined || Object.keys(available_apps).length == 0'
            @appEnter="enterApp($event)"
        />
        <div class="grid-column-3 gap-20">
            <Card 
                :title="$t('notifications')"
                :action="$t('remove_all')"
                :list="$store.getters.unreadNotifications"
                :func="removeNotifications"
                :card_func="removeNotification"
                :className="'notification'"
                :emptyMessage="$t('notifications_empty')"
                :loading='$store.getters.unreadNotifications.length == 0 && loading'
            />
            <Card 
                :title="$t('users')"
                :list="$store.getters.users.data"
                :action="hasPermission(['users.read']) ? $t('view_all') : ''"
                :emptyMessage="$t('users_empty')"
                :className="'user'"
                :func="viewAllUsers"
                :card_func="viewUser"
                :loading='$store.getters.users.data == undefined || Object.keys($store.getters.users.data).length == 0'
            />
            <Card 
                :title="$t('devices')"
                :emptyMessage="$t('devices_empty')"
                :loading="false"
            />
        </div>
        <teleport to="body">
            <transition name="fade">
                <LoadingPage v-if="transition" />
            </transition>
        </teleport>
    </div>
</template>

<script>
import { mapActions } from "vuex";


export default {
    name:"Dashboard",
    data() {
        return {
            loading: true,
            transition: true
        }
    },
    computed: {
        available_apps(){
            let available = [];
            if(this.$store.getters.apps != null)
                this.$store.getters.apps.forEach(app => {
                    if(app.token != 'backoffice')
                        available.push(app)
                });

            return available;
        }
    },
    async beforeMount(){
        await this.getApps();
        await this.getUnreadNotifications("unreadBy="+this.$store.getters.auth.user.id);
        await this.getUsers();
        this.loading = false;
        this.transition = false;

    },
    methods: {
        ...mapActions(["getApps", "getUnreadNotifications", "markReadAll", "clearNotifications", "markRead", "getUsers", "setApp", "setCmsApp"]),
        removeNotifications(){
            this.markReadAll();
            this.clearNotifications();
        },
        removeNotification(id){
            this.markRead(id);
        },
        viewUser(id){
            this.loading = true
            this.transition = true
            this.$router.push({ name: "users_edit", params:{id:id}})
        },
        viewAllUsers(){
            this.loading = true
            this.transition = true
            this.$router.push({ name: "users"})
        },
        async enterApp(app){
            this.$router.push({ name: "dashboard_cms", params: { id: app.id }, query: app.query })
        },
        hasPermission(perm) {
            let permissions = this.$store.getters.auth.permissions;
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(perm.some(item => item == p.split(".")[0])){
                        flag++;
                    }
                });

                return flag == perm.length
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/pages/dashboard.scss";   
</style>