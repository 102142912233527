import Configs from "../../libs/api/configs";

export default {
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0'
    },

    getters: {
        appVersion: (state) => {
            return state.packageVersion
          }
    },

    mutations: {
    },

    actions: {
        getConfig: async ({
            commit
        }, id) => {
            const response = await Configs.getConfig(id);
            return response
        },
    }
};