<template>
    <div class="addon related" :class="[{'open' : open}]">
        <div class="collapsible" @click="open = !open">
            <label>Relacionados</label>
            <i class="i-collapse" />
        </div>
        <Table ref="table" :items="{data: content.relateds, total: content.relateds ? content.relateds.length : 0}" :items2="types" :items3="contents" :loading="loadTable" :tableClass="'related'"
            :pagination="false" @addon-order-related="resortRelated"
            @edit="editFile" @remove="removeRelated" @add="addRelated" @preview="previewFile" :showEntries="false" :showTotal="false" :showFilters="false"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name:"AddonRelated",
    data(){
        return{
            open: false,
            types: [],
            contents: [],
            loadTable: false
        }
    },
    props:{
        content:{
            type:Object,
            default:{}
        }
    },
    async beforeMount(){
        await this.content.type.relateds.forEach( async t => {
            let type = await this.getType(t.type_related_id)
            this.types.push(          {
                label: type.languages[0].name,
                value: type.id
            })
        })

        // let contents = await this.getContents('noaddons=true&limit=99999999999999&order=date_desc&types='+this.content.type.relateds.map( r => {return r.type_related_id}).join(','));

        this.loadTable = true;
        let page = 1, final = [];
        let contents = await this.getContents('noaddons=true&limit=1&order=date_desc&types='+this.content.type.relateds.map( r => {return r.type_related_id}).join(','));
        contents.data = [];
        final = contents
        while(page*100 <= final.total+100){
            this.getContents('noaddons=true&limit=100&order=date_desc&page='+page+'&types='+this.content.type.relateds.map( r => {return r.type_related_id}).join(',')).then( r => {
                let tmp = final.data
                final.data = tmp.concat(r.data)
                if(final.data.length >= final.total){
                    final.data.sort( (a,b) => {
                        let date1 = new Date(a.created_at).getTime();
                        let date2 = new Date(b.created_at).getTime();
                        if (date1 < date2) {
                            return 1
                        } else if (date1 > date2) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    this.contents = final.data
                }
            });
            page++
        }

        this.loadTable = false
    },
    methods:{
        ...mapActions(["getContents", "getType"]),
        addRelated(rel){
            if(this.content.relateds == null || this.content.relateds == undefined){
                this.content.relateds = []
            }

            this.content.relateds.push({
                    contentRelatedType: rel.contentRelatedType,
                    content_id: this.content.id,
                    content_related_id: rel.content_related_id,
                    id: Math.floor(Math.random() * Number.MAX_VALUE)
                }
            )
        },
        removeRelated(id){
            this.content.relateds.forEach( (r,k) => {
                if(r.id == id){
                    this.content.relateds.splice(k,1)
                }
            })
        },
        resortRelated(info){
            //create clone of related to make only 1 update to the UI, instead of 1 for each item changed
            let relateds = []

            this.content.relateds.forEach( r => {
                relateds.push(null)
            });

            let oldOrder = info.oldOrder

            this.content.relateds.forEach( (rel,i) => {
                //give new order to updated item
                if(rel.id == info.item.id){
                    relateds[info.newOrder-1] = info.item
                    //all items before the updated item decrease by 1
                }else if(oldOrder < info.newOrder && (i+1) <= info.newOrder){
                    relateds[i-1] = rel;
                    //all items after the updated item increase by 1
                }else if(oldOrder > info.newOrder && (i+1) >= info.newOrder && (i+1) < oldOrder){
                    relateds[i+1] = rel;
                }else{
                    relateds[i] = rel;
                }
            })

            this.content.relateds = relateds
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>