import _ from "lodash";
import Api from "../api";
import store from "../../../store";

class Blocks extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/blocks";
        this.requiresAuth = true;
    }

    getBlocks(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    getBlock(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    updateBlock(params, data, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.put(url, data, this.requiresAuth);
    }

    createBlock(params, data) {
        let url = this.uri + (params ? "?" + params : "");
        return this.post(url, data, this.requiresAuth);
    }

    deleteBlock(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.delete(url, this.requiresAuth);
    }

    getBlocksCount() {
        let url = this.uri + "/misc/count";
        return this.get(url, {}, this.requiresAuth);
    }

    getGeneric(table, id) {
        let url = "cms/v4/" + table + "/" + id;
        return this.get(url, {}, this.requiresAuth);
    }

    removeSchedule(id) {
        let url = "cms/v4/blocks/schedule/" + id;
        return this.delete(url, {}, this.requiresAuth);
    }
}

export default new Blocks();
