export default {
  state: {
    test: "3",
    base: [
        { id: 1, name: 'Base 1', description: 'Description Base 1', option1: true, option2: 1, option3: 2 },
        { id: 2, name: 'Base 2', description: 'Description Base 2', option1: false, option2: 2, option3: 1 },
    ]
  },

  getters: {
    test: state =>state.test,
    baseList: state => state.base,
    getBaseByAttribute: state => (attr, value) => state.base.find(b => b[attr] == value),
    getBaseById: state => id => state.base.find(b => b.id == id)
  },

  mutations: {
    setBase: (state, base) => {
        // update by id
        state.base = state.base.map(item => item.id == base.id ? base : item)
    },
    newBase: (state, base) => {
        state.base.push(base)
    }
  },

  actions: {
    getBase: async ({ commit }, params) => {
        // get base items
        console.log('Get Base')
    },
    newBase: async ({ commit }, params) => {
        // post base
        commit('newBase', params)
    },
    setBase: async ({ commit }, params) => {
        // update base
        commit('setBase', params)
    }
  }
}
