<template>
    <div class="toast" :class="type">
        <i />
        <label>{{message}}</label>
        <i class="i-close" @click="$parent.toast_show = false"></i> 
    </div>
</template>

<script>
export default {
    name:"Toast",
    props: {
        type: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            cooldown: null
        }
    },
    mounted(){
        clearTimeout(this.cooldown);
        this.cooldown = setTimeout(() => {
            this.$parent.toast_show = false
        }, 5000);
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/components/layout/toasts";
</style>