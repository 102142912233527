<template>
    <div class="block contents" v-if="block != null" :class="[{'open' : open}]">

        <SimpleSelect 
        v-if="!$route.name.includes('edit')"
        :label="'Tipo de bloco'"
        :className="'block-type'"
        :previewIcon="'i-order'"
        @select-data-value="blockType = $event" :options="blockTypes"/>

        <fyi-text
            v-bind:value="block.name"
            v-on:input-text="block.name = $event"
            :label="'Nome'"
            :icon_box="false" :full="true"
        />

        <div class="block-edit" v-if="hasPermission(['block.write'])">
            <div class="collapsible" @click="open($event)">
                <label>Roles Associados</label>
                <i class="i-collapse"/>
            </div>
            <div v-if="roles.length > 0" class="roles-available">
                <fyi-checkbox :alias="'all'" :label="'Todos'"    
                :value="block.roles.includes(0)"  
                :checked="block.roles.includes(0)"                      
                v-on:input="handleRoles(0, $event.checked)" :key="0"/> 

                <fyi-checkbox v-for="(role, k) in roles" :alias="role.name" :label="role.name"    
                :value="block.roles.includes(role.id)"  
                :checked="block.roles.includes(role.id)"                      
                v-on:input="handleRoles( role.id, $event.checked)" :key="k"/> 
            </div>
        </div>

        <div class="block-edit" v-if="hasPermission(['block.write'])">
            <div class="collapsible" @click="open($event)">
                <label>Páginas Associadas</label>
                <i class="i-collapse"/>
            </div>
            <div class="pages-available">
                <div>
                    <label>Link</label>
                    <label>Nome</label>
                    <label>Configuração</label>
                </div>
                <div v-for="(item, k) in pagesParsed" :key="k">
                    <label>{{item.type == "type" ? "Tipo" : (item.type == "content" ? "Conteúdo" : item.name )}}</label>
                    <label>{{item.name}}</label>
                    <label>{{item.config == "contents" ? 'Todas as páginas de conteúdo' : (item.config == "all" ? 'Todas as páginas de tipo e conteúdo' : 'Todas as páginas de tipo')}}</label>
                    <span @click="item.show = (item.show == 1 ? 0 : 1)">{{item.show == 1 ? 'Esconder' : 'Mostrar' }}</span>
                    <span @click="removePage(item.id)">Eliminar</span>
                </div>
                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() => addPage = true"
                />
            </div>
        </div>

        <div class="block-edit" v-if="hasPermission(['block.write'])">
            <div class="collapsible" @click="open($event)">
                <label>Templates Associados</label>
                <i class="i-collapse"/>
            </div>
            <div class="templates-available">
                <Table ref="table" :items="{data: associatedTemplates, total: associatedTemplates.length}" :items2="templates" :items3="'positions'" :tableClass="'templates'"
                    :pagination="false" @addon-order-related="resortRelated"
                    @remove="removeTemplate" @add="addTemplate" :showEntries="false" :showTotal="false" :showFilters="false"
                />
            </div>
        </div>


        <div class="block-edit" v-if="block.type == 'content'">
            <div class="collapsible" @click="open($event)">
                <label>Conteúdo</label>
                <i class="i-collapse"/>
            </div>

        <fyi-checkbox alias="url-dependence" :label="'Dependente do URL'"    
            :value="block.data.url_dependence_limit == 1"  
            :checked="block.data.url_dependence_limit == 1"                      
            v-on:input="block.data.url_dependence_limit = $event.checked ? 1 : 0"/>

        <fyi-number
            v-bind:value="block.data.contents_limit"
            v-on:input-number="block.data.contents_limit = $event"
            :alias="'limit'"
            :classe="'limit'"
            :label="'Limite de Conteúdos'"
            :show="true"
            :placeholder="''"
            :disabled="false"
            :required="true"
            :inputError="false"

        />

        <label class="types-available-label">Tipos de Conteúdo Associados</label>
        <Search
            @done-search="searchTypes($event)"
            :placeholder="$t('fast_search')"
        />
        <div class="types-available" ref="types_available">
            <fyi-checkbox v-for="(type, k) in typesModel" :alias="type.languages[0].alias" :label="type.languages[0].name"    
            :value="block.data.types.includes(type.id)"  
            :checked="block.data.types.includes(type.id)"                      
            v-on:input="handleTypes( type.id, $event.checked)" :key="k"/> 
        </div>

        <SimpleSelect 
        :label="'Ordenação'"
        :className="'order'"
        :previewIcon="'i-order'"
        @select-data-value="order = $event" :options="options" :key="optionsKey"/>

        <Table ref="table" :items="{data: associated, total: associated.length}" :items2="types" :items3="contents" :tableClass="'related'"
            :pagination="false" @addon-order-related="resortRelated" :tableOrder="order != 'manual' ? 'disabled' : ''" :loading="loadTable"
            @remove="removeContent" @add="addContent" :addDisabled="block != null ? associated.length >= block.data.contents_limit : true" :showEntries="false" :showTotal="false" :showFilters="false" :key='updateAssociated'
        />
        </div>

        <div class="block-edit" v-if="block.type == 'content' && hasPermission(['block.write'])">
            <div class="collapsible" @click="open($event)">
                <label>Campos</label>
                <i class="i-collapse"/>
            </div>
            <div class="block-fields">
                <div v-for="(item,k) in block.data.fields" :key="k">
                    <SimpleSelect :className="tableOrder" @select="orderFields({newOrder: parseInt($event), item: item, oldOrder: k+1})" :previewIcon="'i-order'" :options="fieldsOrders(item, block.data.fields.length, k)"/>
                    <fyi-text
                        v-bind:value="item.field"
                        v-on:input-text="item.field = $event"
                        :placeholder="'Nome'"
                        :icon_box="false" :full="true"
                    />
                    <fyi-text
                        v-bind:value="item.options == null ? '' : (item.options.length != undefined ? item.options : item.options.join(','))"
                        v-on:input-text="item.options = $event.split(',')"
                        :placeholder="'Opções'"
                        :icon_box="false" :full="true"
                    />
                    <span @click="removeField(k)">Eliminar</span>
                </div>
                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() => addField()"
                />
            </div>
        </div>

        <div class="block-edit images" v-if="block.type == 'image'">
            <div class="collapsible" @click="open($event)">
                <label>Imagens</label>
                <i class="i-collapse"/>
            </div>
            <Table ref="table" :items="{data: parsedImages, total: parsedImages.length}"  :loading="false" :tableClass="'attachments'"
                :pagination="false" @addon-order="resortImages"
                @edit="editImage" @remove="removeImage" @preview="previewImage" :showEntries="false" :showTotal="false" :showFilters="false" :key="updateImageTable"
            />
            <fyi-button
                :label="'Adicionar'"
                :className="'default add'"
                :onclick="() => mediaOn = true"
            />
        </div>

        <div class="block-edit text" v-if="block.type == 'text'">
            <div class="collapsible" @click="open($event)">
                <label>Texto</label>
                <i class="i-collapse"/>
            </div>
            <fyi-textarea
                v-bind:value="block.data[0].text"
                @input="block.data[0].text = $event"
                :toolbar="block.data[0].type == 'html' ? 'full' : 'mini'"
                :textAreaID="'body'"
            />
        </div>

        <div class="block-edit menu" v-if="block.type == 'menu'">
            <div class="collapsible" @click="open($event)">
                <label>Menu</label>
                <i class="i-collapse"/>
            </div>
            <MenusEdit ref="menu" :block="block" :showToolbar="false" :className="'embedded'"/>
        </div>

        <div class="block-edit" v-if="block.type == 'form' && hasPermission(['block.write'])">
            <div class="collapsible" @click="open($event)">
                <label>Formulário</label>
                <i class="i-collapse"/>
            </div>
            <div class="block-form">
                <SimpleSelect 
                :label="'Tipo'"
                :className="'order'"
                :previewIcon="'i-order'"
                @select-data-value="block.data.type_id = $event" :options="typesModel.map( t => {return {label: t.languages[0].name,value: t.id, checked: block.data.type_id == t.id }})" :key="1"/>                
                <div v-for="(item,k) in block.data.fields" :key="k">
                    <SimpleSelect :className="tableOrder" @select="orderFields({newOrder: parseInt($event), item: item, oldOrder: item.orderfield})" :previewIcon="'i-order'" :options="fieldsOrders(item, block.data.fields.length, item.orderfield)"/>
                    <SimpleSelect :className="'field-select'" @select-data-value="selectField($event, item)" :previewIcon="'i-order'" :options="parseFields(item)"/>
                    <SimpleSelect :className="'required'" @select-data-value="item.required = $event" :previewIcon="'i-order'" :options="[{
                        label: 'Sim',
                        value: 1,
                        checked: item.required == 1
                    }, 
                    {
                        label: 'Não',
                        value: 0,
                        checked: item.required == 0
                    }]"/>
                    <span @click="removeFormField(k)">Eliminar</span>
                </div>
                <div>
                    <span></span>
                    <SimpleSelect :className="'field-select'" @select-data-value="formTemp.id = $event" :previewIcon="'i-order'" :options="parseFields(formTemp)"/>
                    <SimpleSelect :className="'required'" @select-data-value="formTemp.required = $event" :previewIcon="'i-order'" :options="[{
                        label: 'Sim',
                        value: 1,
                        checked: false
                    }, 
                    {
                        label: 'Não',
                        value: 0,
                        checked: false
                    }]"/>
                    <span @click="addFormField()">Adicionar</span>
                </div>
            </div>
        </div>

        <fyi-button
            :label="'Guardar'"
            :className="'default save'"
            :loading="submitStatus=='PENDING'"
            :onclick="() => save()"
        />
    </div>
    <teleport to="body">
        <div class="bg" v-if="addPage">
            <div class="add-page">
                <label>Adicionar página</label>
                <i class="i-close" @click="addPage = false"/>
                <SimpleSelect 
                    :label="'Especificação'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.specification = $event" :options="specification"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'type' || pageTemp.specification == 'content'"
                    :label="'Tipo'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="fetchContent($event)" :options="typesModel.map( t => {return {label: t.languages[0].name,value: t.id, checked: pageTemp.type == t.id }})" :key="1"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'content'"
                    :label="'Conteúdo'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.content = $event" :options="addPageContent" :key="addPageContentKey"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'type'"
                    :label="'Configuração'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.config = $event" :options="addPageConfig"/>

                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() => addPageInfo()"
                />
            </div>
        </div>
    </teleport>
    <teleport to="body">
        <div v-if="mediaOn" class="modal-media">
            <Media :modal="true" :mime="'image'" :multiSelect="true"
            @add-files="addImages"/>
        </div>
    </teleport>
    <teleport to="body">
        <div v-if="showEditImage != null" class="bg">
            <div class="edit-image">
                <label>Editar</label>
                <i class="i-close" @click="showEditImage = null"/>
                <div class="form">
                    <fyi-text
                        v-bind:value="showEditImage.name"
                        v-on:input-text="showEditImage.name = $event"
                        :label="'Nome'"
                        :placeholder="'Nome'"
                        :icon_box="false" :full="true"
                    />
                    <SimpleSelect 
                        :label="'Especificação'"
                        :className="'order'"
                        :previewIcon="'i-order'"
                        @select-data-value="showEditImage.url_type = $event" :options="[{
                            label: 'Externo',
                            value: 'external',
                            checked: showEditImage.url_type == 'external'
                        },
                        {
                            label: 'Tipo',
                            value: 'type',
                            checked: showEditImage.url_type == 'type'
                        },
                        {
                            label: 'Conteúdo',
                            value: 'content',
                            checked: showEditImage.url_type == 'content'
                        },]"/>
                    <SimpleSelect 
                        v-if="showEditImage.url_type == 'type' || showEditImage.url_type == 'content'"
                        :label="'Tipo'"
                        :className="'order'"
                        :allowSearch="true"
                        :previewIcon="'i-order'"
                        @select-data-value="fetchContentImage($event)" :options="typesModel.map( t => {return {label: t.languages[0].name,value: t.id, checked: showEditImage.type == t.id }})" :key="1"/>

                    <SimpleSelect 
                        v-if="showEditImage.url_type == 'content'"
                        :label="'Conteúdo'"
                        :className="'order'"
                        :previewIcon="'i-order'"
                        :allowSearch="true"
                        @select-data-value="showEditImage.url = $event" :options="editingContent"/>

                    <fyi-text
                        v-if="showEditImage.url_type == 'external'"
                        v-bind:value="showEditImage.url"
                        v-on:input-text="showEditImage.url = $event"
                        :label="'Url'"
                        :icon_box="false" :full="true"
                    />
                </div>
                <div class="button-bar">
                    <fyi-button
                        :label="'Atualizar imagem'"
                        :className="'default add'"
                        :onclick="() => addImageInfo()"
                    />
                    <fyi-button
                        :label="'Cancelar'"
                        :className="'default external'"
                        :onclick="() => showEditImage = null"
                    />
                </div>
            </div>
        </div>
    </teleport>
    <teleport to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
    </teleport>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenusEdit from "./../menus/MenusEdit.vue"

export default {
    name:"BlocksEdit",
    components: {
        MenusEdit
    },
    data(){
        return{
            options: [
                {
                    label: "Nome Ascendente",
                    value: "name_asc",
                    checked: false
                },
                {
                    label: "Nome Descendente",
                    value: "name_desc",
                    checked: true
                },
                {
                    label: "Data de Criação Ascendente",
                    value: "date_asc",
                    checked: false
                },
                {
                    label: "Data de Criação Descendente",
                    value: "date_desc",
                    checked: false
                },
                {
                    label: "Data de Atualização Ascendente",
                    value: "update_asc",
                    checked: false
                },
                {
                    label: "Data de Atualização Descendente",
                    value: "update_desc",
                    checked: false
                },
                {
                    label: "Manual",
                    value: "manual",
                    checked: false
                },
                {
                    label: "Aleatório",
                    value: "random",
                    checked: false
                },
            ],
            specification: [{
                label: "Tudo",
                value: "all",
                checked: false
            },
            {
                label: "Homepage",
                value: "home",
                checked: false
            },
            {
                label: "Tipo",
                value: "type",
                checked: false
            },
            {
                label: "Conteúdo",
                value: "content",
                checked: false
            },],
            addPageConfig: [{
                label: "Todas as páginas de tipo",
                value: "",
                checked: false
            },{
                label: "Todas as páginas de conteúdo",
                value: "_contents",
                checked: false
            },{
                label: "Todas as páginas de conteúdo e tipo",
                value: "_all",
                checked: false
            }],
            blockTypes: [
                {
                    label: "Conteúdo",
                    value: "content",
                    checked: true
                },
                {
                    label: "Menu",
                    value: "menu",
                    checked: false
                },
                {
                    label: "Texto",
                    value: "text",
                    checked: false
                },
                {
                    label: "Imagem",
                    value: "image",
                    checked: false
                },
                {
                    label: "Link",
                    value: "link",
                    checked: false
                },
                {
                    label: "Formulário",
                    value: "form",
                    checked: false
                }
            ],
            blockType: "",
            optionsKey: 1,
            order: null,
            types: [],
            submitStatus:"READY",
            typesModel:[],
            block: null,
            contents: [],
            associated: [],
            associatedTemplates: [],
            fields: [],
            roles: [],
            pagesParsed: [],
            templates: [],
            templatesModel: [],
            toast_type: '',
            toast_message: '',
            toast_show: false,
            addPage: false,
            pageTemp: {
                data: {
                    id:'',
                    languages: [{label:''}]
               },
               field: "",
               orderfield: "",
               required: 0
            },
            formTemp: {},
            addPageContent: [],
            addPageContentKey: 1,
            mediaOn: false,
            updateImageTable: 1,
            updateAssociated: 1,
            showEditImage: null,
            editingContent: [],
            loadTable: false
        }
    },
    computed: {
        parsedImages(){
            let filtered = this.block.data.filter( c => { return c.language_id == 1 })

            filtered.forEach( (f,k) => {
                f.orderattachment = k + 1;
            })

            return filtered;
        }
    },
    watch: {
        blockType(_new, _old){
            this.block.type = _new
            this.setupBlockTypes();
            this.setupBlock();
        }
    },
    async beforeMount(){
            if(this.$route.name.includes("edit")){
                this.block = await this.getBlock({id: this.$route.params.block_id})
            }else{
                this.block = {
                    name: '',
                    pages: [],
                    roles: [],
                    templates: [],
                    published: 1,
                    data: {
                        contents: [],
                        types: [],
                        url_dependence_limit: null,
                        contents_limit: 0,
                        contents_order: 'date_desc',
                        data: [],
                        fields: []
                    },
                    type: 'content'
                }
            }

            await this.setupBlock();

            try{this.roles = await this.getRoles();}catch(error){}

            this.$parent.$parent.loading = false;
            this.$parent.$parent.transition = false;

            if(this.types.length > 0){
                this.loadTable = true;
                let page = 1, final = [];
                let contents = await this.getContents('noaddons=true&limit=1&order=date_desc&types='+this.types.map( r => {return r.value}).join(','));
                contents.data = [];
                final = contents
                while(page*100 <= final.total+100){
                    this.getContents('noaddons=true&limit=100&order=date_desc&page='+page+'&types='+this.types.map( r => {return r.value}).join(',')).then( r => {
                        let tmp = final.data
                        final.data = tmp.concat(r.data)
                        if(final.data.length >= final.total){
                            final.data.sort( (a,b) => {
                                let date1 = new Date(a.created_at).getTime();
                                let date2 = new Date(b.created_at).getTime();
                                if (date1 < date2) {
                                    return 1
                                } else if (date1 > date2) {
                                    return -1
                                } else {
                                    return 0
                                }
                            })

                            this.contents = final.data
                            if(this.block.data.contents != null){
                                let associated = this.contents.filter( c => {return this.block.data.contents.includes(c.id)})
                                this.associated = associated.map( a => {
                                    return {
                                        contentRelatedType: a.type.id,
                                        content_related_id: a.id,
                                        ...a
                                        }
                                })
                                this.updateAssociated++;
                                this.loadTable = false;
                            }else{
                                this.loadTable = false;
                            }
                        }
                    });
                    page++
                }

            }
    },
    methods:{
        ...mapActions(["getContents", "getContent", "getTypes", "getBlock", "updateBlock", "getRoles", "getTemplates", "getFields", "createBlock"]),
        hasPermission(prmsion) {
            let permissions = this.$store.getters.auth.permissions
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(prmsion.some(item => item == p)){
                        flag++;
                    }
                });

                return flag == prmsion.length
            }
        },
        open(ev){
            ev.target.parentElement.classList.toggle('open')
        },
        addContent(c){
            c.id = c.content_related_id
            this.associated.push(c)
        },
        removeContent(id){
            this.associated.forEach( (c,k) => {
                if(c.id == id){
                    this.associated.splice(k,1)
                }
            })
        },
        addTemplate(c){
            c.id = c.content_related_id
            this.associatedTemplates.push(c)
        },
        removeTemplate(id){
            this.associatedTemplates.forEach( (c,k) => {
                if(c.id == id){
                    this.associatedTemplates.splice(k,1)
                }
            })
        },
        resortRelated(info){
            //create clone of related to make only 1 update to the UI, instead of 1 for each item changed
            let associated = []

            this.associated.forEach( r => {
                associated.push(null)
            });

            let oldOrder = info.oldOrder

            this.associated.forEach( (rel,i) => {
                //give new order to updated item
                if(rel.id == info.item.id){
                    associated[info.newOrder-1] = info.item
                    //all items before the updated item decrease by 1
                }else if(oldOrder < info.newOrder && (i+1) <= info.newOrder && (i+1) >= oldOrder){
                    associated[i-1] = rel;
                    //all items after the updated item increase by 1
                }else if(oldOrder > info.newOrder && (i+1) >= info.newOrder && (i+1) < oldOrder){
                    associated[i+1] = rel;
                }else{
                    associated[i] = rel;
                }
            })

            this.associated = associated
        },
        async handleTypes(id, add){
            if(add){
                this.typesModel.forEach( t => {
                    if(t.id == id)
                        this.types.push(          {
                            label: t.languages[0].name,
                            value: t.id,
                            checked: false
                        })
                })

                let contents = await this.getContents('noaddons=true&limit=99999999999999&types='+id);
                this.contents = this.contents.concat(contents.data)

            }else{

                this.types = this.types.filter( t => { return t.value != id})

                this.contents = this.contents.filter( c => { return c.type.id != id})
            }
        },
        handleRoles(id, add){
            if(add){
                this.block.roles.push(id)
            }else{
                this.block.roles.splice(this.block.roles.indexOf(id), 1)
            }
        },
        async parsePages(){
            let pages = [], contents = [];
            this.block.pages.forEach( p => {
                let tmp = { type: p.page.split("_")[0]}

                if(tmp.type == "type"){
                    tmp.id = p.page.split("_")[1]
                    let filt = this.typesModel.filter( t => { return t.id == tmp.id})
                    if(filt.length > 0){
                        tmp.name = filt[0].languages[0].name
                        tmp.config = p.page.split("_")[2]
                    }
                }else if(tmp.type == "content"){
                    contents.push(p.page.split("_")[1])
                    tmp.id = p.page.split("_")[1]
                    tmp.config = p.page.split("_")[2]
                }else if(tmp.type == "all"){
                    tmp.name = "Todas as páginas"
                }else if(tmp.type == "home"){
                    tmp.name = "Homepage"
                }else{
                    tmp.name = tmp.type
                }
                
                tmp.show = p.show;
                tmp.page = p.page

                if(tmp.name || tmp.type == 'content' )
                    pages.push(tmp)
            })

            if(contents.length > 0){
                let c = await this.getContents('noaddons=true&limit=99999999999999&contents='+contents.join(','));

                c.data.forEach( cc => {
                    pages.forEach( p => {
                        if(p.type == "content" && cc.id == p.id){
                            p.name = cc.languages[0].name
                        }
                    })
                })
            }


            this.pagesParsed = pages;

            return pages;
        },
        async fetchContent(t){
            this.pageTemp.type = t; 
            if(this.pageTemp.specification == "content"){
                let c = await this.getContents('noaddons=true&limit=99999999999999&order=date_desc&types='+this.pageTemp.type);
                let content = [];
                c.data.forEach( cc => {
                    content.push({
                        label: cc.languages[0].name,
                        value: cc.id,
                        checked: false
                    })
                })
                this.addPageContent = content;
                // this.addPageContentKey++;
            }
        },
        async fetchContentImage(t,info = null){
            let array = this.showEditImage

            if(info != null)
                array = info

            array.type = t; 

            if(array.url_type == "type")
                array.url = t; 
            
            if(array.url_type == "content"){
                let c = await this.getContents('noaddons=true&limit=99999999999999&order=date_desc&types='+array.type);
                let content = [];
                c.data.forEach( cc => {
                    content.push({
                        label: cc.languages[0].name,
                        value: cc.id,
                        checked: array.url == cc.id
                    })
                })
                this.editingContent = content;
            }
        },
        removePage(id){
            this.pagesParsed.forEach( (c,k) => {
                if(c.id == id){
                    this.pagesParsed.splice(k,1)
                }
            })
        },
        addPageInfo(){
            this.block.pages.push({
                page: (this.pageTemp.specification == 'home' || this.pageTemp.specification == 'all' ? this.pageTemp.specification : ( this.pageTemp.specification == 'type' ? (this.pageTemp.specification + "_" + this.pageTemp.type + this.pageTemp.config) : (this.pageTemp.specification + "_" + this.pageTemp.content))),
                language_id: 1,
                show: 1
            })
            this.parsePages()
            this.pageTemp = {}
            this.addPage = false
        },
        addField(){
            this.block.data.fields.push({
                field:'',
                options: '',
                orderfield: this.block.data.fields.length+1
            })
        },
        removeField(k){
            this.block.data.fields.splice(k,1)
        },
        fieldsOrders(item, total, index = null){
            let list = [];
            //set the select dropdowns with all the possible positions for each item, and the current one
            for (let i = 0; i < total; i++) {
                list.push(                
                {
                    label: i+1,
                    value: i+1,
                    checked: item.orderfield ? (item.orderfield == i+1) : (i == index)
                })
            }
            return list

        },
        orderFields(info){
            //create clone of related to make only 1 update to the UI, instead of 1 for each item changed
            let fields = this.block.data.fields;
            let oldOrder = info.item.orderfield

            fields.forEach( field => {
                //give new order to updated item
                if(field.orderfield == oldOrder){
                    field.orderfield = parseInt(info.newOrder)
                    //all items before the updated item decrease by 1
                }else if(oldOrder < info.newOrder && field.orderfield <= info.newOrder && field.orderfield >= oldOrder){
                    field.orderfield -= 1;
                    //all items after the updated item increase by 1
                }else if(oldOrder > info.newOrder && field.orderfield >= info.newOrder && field.orderfield < oldOrder){
                    field.orderfield +=1;
                }else{
                    field.orderfield = field.orderfield
                }
            })

            this.block.data.fields = fields.sort( (a,b) => { return a.orderfield > b.orderfield ? 1 : -1})
        },
        removeImage(k){
            this.parsedImages.splice(k,1)
            this.updateImageTable++;
        },
        async editImage(image){
            let clone = image
            if(clone.url_type == 'type')
                clone.type = clone.url
            else if(clone.url_type == 'content'){
                let c = await this.getContent({params: "", id: clone.url});
                await this.fetchContentImage(c.type.id, clone);
                clone.type = c.type.id
            }

             this.showEditImage = image
        },
        addImageInfo(){
            this.block.data.forEach( (i,k) => {
                if(i.id == this.showEditImage.id)
                    this.block.data[k] = this.showEditImage
            })

            this.showEditImage = null
        },
        resortImages(info){
            let attachments = this.parsedImages;
            let oldOrder = info.item.orderattachment

            attachments.forEach( attach => {
                //give new order to updated item
                if(attach.id == info.item.id){
                    attach.orderattachment = parseInt(info.newOrder)
                    //all items before the updated item decrease by 1
                }else if(oldOrder < info.newOrder && attach.orderattachment <= info.newOrder && attach.orderattachment >= oldOrder){
                    attach.orderattachment -= 1;
                    //all items after the updated item increase by 1
                }else if(oldOrder > info.newOrder && attach.orderattachment >= info.newOrder && attach.orderattachment < oldOrder){
                    attach.orderattachment +=1;
                }else{
                    attach.orderattachment = attach.orderattachment
                }
            })

            attachments.sort( (a,b) => {return a.orderattachment >= b.orderattachment ? 1 : -1})

            this.parsedImages = attachments
        },
        previewImage(image){
            window.open(this.$store.getters.appConfig.url + image.image, "_blank");
        },
        addImages(files){
            let add = this.parsedImages;
            
            files.forEach( f => {
                add.push(
                    {
                        id: Math.floor(Math.random() * 10000),
                        image: f.path,
                        language_id: 1,
                        orderattachment: add.length + 1,
                        name: f.name
                    }
                )
            })
            this.parsedImages = add;
        },
        async save(){
            try{
                this.submitStatus = "PENDING"
                let block = {}
                if(this.hasPermission(['block.write'])){
                    block = {
                        name: this.block.name,
                        data: {},
                        roles: this.block.roles,
                        templates:[],
                        pages: []
                    }

                    if(!this.$route.name.includes("edit")){
                        block.type = this.block.type
                        block.published = 1
                    }

                    this.associatedTemplates.forEach( t => {
                        block.templates.push({
                            template_id: t.contentRelatedType,
                            position: t.content_related_id,
                            orderposition: t.orderposition,
                            plugin: t.plugin
                        })
                    })

                    this.pagesParsed.forEach( t => {
                        block.pages.push({
                            page: t.page,
                            language_id: 1,
                            show: t.show == 1 ? true: false,
                        })
                    })
                }else{
                    block = {
                        name: this.block.name,
                        data: {},
                    }
                }

            
                if(this.block.type == "content")
                    block.data = {
                        contents_order: this.order,
                        contents_limit: this.block.data.contents_limit,
                        types: this.types.map( t => {return t.value}),
                        contents: this.associated.map( a => {return a.content_related_id}),
                        url_dependence_limit: this.block.data.url_dependence_limit,
                        fields: this.block.data.fields,
                        options: this.block.data.options
                    }

                if(this.block.type == "image")
                    block.data = this.parsedImages

                if(this.block.type == "text")
                    block.data = this.block.data

                if(this.block.type == "menu")
                    block.data = this.$refs.menu.$refs.list.gatherInfo()

                if(this.block.type == "form")
                    block.data = [this.block.data]

                if(this.block.type == "link")
                    block.data = this.block.data

                if(this.block.type == "calendar")
                    block.data = this.block.data

                if(this.$route.name.includes("edit"))
                    await this.updateBlock({data: block, id: this.block.id})
                else
                    await this.createBlock({data: block})

                this.submitStatus = "READY"
                this.toast_type = "success";
                this.toast_message = "Alterado com sucesso";
                this.toast_show = true;
            }catch(error){
                console.log(error)
                this.toast_type = "error";
                this.toast_message = "Aconteceu um erro. Tente novamente.";
                this.toast_show = true;
            }

        },
        searchTypes(value){
            this.$refs.types_available.querySelectorAll('div label').forEach( i => {
                    i.parentElement.classList.remove('hidden')
                }
            );

            this.$refs.types_available.querySelectorAll('div label').forEach( i => {
                if(i.innerHTML.toLowerCase().includes(value.toLowerCase())){
                    i.parentElement.classList.remove('hidden')
                }else{
                    i.parentElement.classList.add('hidden')
                }
            })
        },
        parseFields(item){
            let fields = [];

            this.fields.forEach( f => {
                fields.push({
                    label: f.languages[0].label ? f.languages[0].label : f.field,
                    value: f.id,
                    checked: item.data && f.id == item.data.id
                })
            })

            return fields
        },
        selectField(id, item){
            this.fields.forEach( f => {
                this.block.data.fields.forEach( (bf, k) => {
                    if(f.id == id && item.field == bf.field){
                        this.block.data.fields[k] = {
                            data: {
                                id: f.id,
                                languages: [{label:f.languages[0].label}]
                            },
                            field: "field_" + f.id,
                            orderfield: bf.orderfield,
                            required: bf.required
                        }

                        item = this.block.data.fields[k]
                    }
                })
            })
        },
        addFormField(){
            this.block.data.fields.push({
               data: {
                id: this.formTemp.id,
               },
               field: "field_" + this.formTemp.id,
               orderfield: this.block.data.fields.length + 1,
               required: this.formTemp.required
            })
            this.formTemp = {
                data: {
                    id:'',
                    languages: [{label:''}]
               },
               field: "",
               orderfield: "",
               required: 0
            }
        },
        removeFormField(k){
            this.block.data.fields.splice(k,1)
        },
        setupBlockTypes(){
            if(this.block.type == "content"){
                this.block.data = {
                    contents: [],
                    types: [],
                    url_dependence_limit: null,
                    contents_limit: 0,
                    contents_order: 'date_desc',
                    data: [],
                    fields: []
                }
            }else if(this.block.type == "text"){
                this.block.data = [{
                    language_id: 1,
                    text: '',
                    type: 'html'
                }]
            }else if(this.block.type == "form"){
                this.block.data = {
                    type_id: 0,
                    language_id: 1,
                    content_status: 'pending',
                    fields: []
                }
            }else{
                this.block.data = []
            }
        },
        async setupBlock(){
            this.typesModel = await this.getTypes()
            if(this.block.type == "content"){
                if(this.block.data.types !== null){
                    this.typesModel.forEach( t => {
                        if(this.block.data.types.includes(t.id)){
                            this.types.push(          {
                                label: t.languages[0].name,
                                value: t.id,
                                checked: false
                            })
                        }
                    })
                }else{
                    this.block.data.types = []
                }
            }

            if(this.block.type == 'form'){
                this.fields = await this.getFields();
            }

            this.options.forEach( opt => {
                if(opt.value == this.block.data.contents_order){
                    this.order = opt.value
                    opt.checked = true;
                }
            })
            this.optionsKey++;

            try{
                this.templatesModel = await this.getTemplates();
                let ass = this.block.templates.map( t => { return t.template_id})

                this.templatesModel.forEach( t => {
                    this.templates.push({
                        label: t.name,
                        value: t.id,
                        positions: t.positions
                    })
                })

                this.associatedTemplates = this.block.templates.map( a => {
                    return {
                        id: a.template_id,
                        contentRelatedType: a.template_id,
                        content_related_id: a.position,
                        ...a
                    }
                }).filter( a => { return ass.includes(a.id)})
            }catch(error){

            }
            
            this.parsePages();
        }
    },
    mounted(){
        if(this.$route.name.includes("edit"))
            this.$parent.$parent.view = this.$t('edit')
        else
            this.$parent.$parent.view = this.$t('Criar')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/blocks.scss";   
</style>