<template>
  <div class="unauthorized">
    <p>User sem permissão</p>
  </div>
</template>

<script>

export default {
  name: 'error-unauthorized'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
