<template>
    <div id="content-form" class="content-form" v-if="content != null">
        <div v-if="!mediaOn" class="form">
            <!--Base content!-->
            <div class="path">
                <label>Caminho</label>
                <label v-if="parent_types.length != 0">
                    <span v-for="(item,k) in parent_types" :key="k" @click="$router.push({ name: 'pages_cms', params: { id: $route.params.id }, query: { filter: item.id } })" class="parent" >{{item.languages[0].name}}/</span>
                    <span @click="$router.push({ name: 'pages_cms', params: { id: $route.params.id }, query: { filter: content.type.id } })" class="parent">{{content.type.languages[0].name}}/</span>
                    <span>{{content.languages[0].name}}</span>
                </label>
                <label v-else>
                    <span @click="$router.push({ name: 'pages_cms', params: { id: $route.params.id }, query: { filter: content.type.id } })" class="parent">{{content.type.languages[0].name}}/</span>
                    <span>{{content.languages[0].name}}</span>
                </label>
            </div>
            <fyi-text
                v-bind:value="content.languages[0].name"
                @input-text="content.languages[0].name = $event; $parent.$parent.content_name=$event"
                :label="'Título'"
                :required="true"
                :inputError="true"
                :charCount="true"
                :icon_box="false" :full="true"/>
            <fyi-text
                v-bind:value="content.languages[0].sub_name"
                @input-text="content.languages[0].sub_name = $event; $parent.$parent.content_name=$event"
                :label="'Subtítulo'"
                :charCount="true"
                :icon_box="false" :full="true"/>
            <fyi-text 
                id="url"
                v-bind:value="content.languages[0].alias"
                @input-text="content.languages[0].alias = $event"
                :label="'URL'"
                :icon_box="false" :full="true"/>
                <span><i class="i-info"></i> se deixar vazio assume o título</span>
            <fyi-textarea
                v-bind:value="content.languages[0].body"
                @input="content.languages[0].body = $event"
                :label="'Texto'"
                :toolbar="'full'"
                :textAreaID="'body'"
            />
            <fyi-textarea
                v-bind:value="content.languages[0].description"
                @input="content.languages[0].description = $event"
                :label="'Descrição'"
                :toolbar="'mini'"
                :textAreaID="'description'"
            />
            <Date v-if="$route.name.includes('edit')" :value="content.created_at.split(' ')[0]" 
            @update:modelValue="content.created_at = (new Date($event)).toLocaleDateString('en-CA', {
                format: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) + ' ' + content.created_at.split(' ')[1]" 
            :label="'Data de Criação'"/> 
            <Time v-if="$route.name.includes('edit')" :value="content.created_at.split(' ')[1]"
            @update:modelValue="content.created_at = content.created_at.split(' ')[0] + ' ' + $event.hours + ':' + ($event.minutes < 10 ? ('0'+$event.minutes) : $event.minutes) + ':00'"/>
            <!--Addons!-->
            <ContentAddons ref="addons" :addons="content.type.addons ? content.type.addons : type.addons" :content="content" 
            @add-files-attachments="addFilesAttachments" @remove-files-attachments="removeFilesAttachments"
            />
        </div>
        <div v-if="!mediaOn" class="options-sidebar">
            <label class="title">{{ content.published == 1 ? 'Publicado' : 'Despublicado' }}</label>
            <i v-if="$route.name.includes('edit')" class="i-swap" @click="togglePublish"/>
            <label><span>Autor:</span> <span v-if="content.user!=null">{{content.user.name}}</span></label>
            <label><span>Update:</span> <span>
                {{
                new Date(content.updated_at).toLocaleDateString("pt-PT", {
                    format: "short",
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                })
            }}</span><i class="i-info"></i></label>
            <div class="buttons">
                <span v-if="$route.name.includes('edit')" :onclick="removeContent">Eliminar</span>
                <fyi-button
                    :label="($route.name.includes('edit') ? 'Atualizar' : 'Criar')"
                    :loading="submitStatus=='PENDING'"
                    :className="'default ' + ($route.name.includes('edit') ? 'update' : 'create')"
                    :onclick="saveContent"
                />
            </div>

            <div class="section scheduler" ref="scheduler" >
                <div class="bar" @click="openBar('scheduler')">
                    <i class="i-collapse"/>
                    <label>Agendar publicação</label>
                </div>
                <div class="options">
                    <Date :value="content['schedule_'+schedulerState+'ed_at'] != null ? content['schedule_'+schedulerState+'ed_at'].split(' ')[0] : ''" 
                    @update:modelValue="schedulerDate = $event"
                    :label="'Data publicação'"/> 
                    <Time :value="content['schedule_'+schedulerState+'ed_at'] != null ? content['schedule_'+schedulerState+'ed_at'].split(' ')[1].slice(0, -3) : ''"
                    @update:modelValue="schedulerTime = $event"
                    :label="'Hora'"/>
                    <Switch :enabled="content.schedule_unpublished_at != null" :showLabel="true" :label="'Despublicação'" @click="schedulerState = ($event ? 'unpublish' : 'publish')"/>
                </div>
            </div>
            <div class="section pictures" ref="pictures" >
                <div class="bar" @click="openBar('pictures')">
                    <i class="i-collapse"/>
                    <label>Imagens</label>
                </div>
                <div class="options">
                    <div class="main">                    
                        <label>Imagem principal</label>
                        <img ref="main_image" v-if="content.languages[0].image" :src="$store.getters.appConfig.url + content.languages[0].image">
                        <div class="no-image" v-else/>
                        <div v-if="content.languages[0].image" class="overlay">
                            <i class="i-remove" @click="removeImage('main')"/>
                        </div>
                        <fyi-button
                            :label="'Nova Imagem'"
                            :icon="'i-upload'"
                            :className="'default new-image'"
                            :onclick="() => addImage('main')"
                        />
                    </div>
                    <div class="secondary">
                        <label>Imagem alternativa</label>
                        <img ref="alternative_image" v-if="content.languages[0].alternative_image" :src="$store.getters.appConfig.url + content.languages[0].alternative_image">
                        <div class="no-image" v-else/>
                        <div v-if="content.languages[0].alternative_image" class="overlay">
                            <i class="i-remove" @click="removeImage('alternative')"/>
                        </div>
                        <fyi-button
                            :label="'Nova Imagem'"
                            :icon="'i-upload'"
                            :className="'default new-image'"
                            :onclick="() => addImage('alternative')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div onclick="this.previousElementSibling.classList.toggle('open')" class="sidebar-mobile-tab">
            <i class="i-collapse"></i>
        </div>
        <Media v-if="mediaOn" :modal="true" :mime="'image'" :multiSelect="false" :destination="destination"
        @add-files-alternative="content.languages[0].alternative_image = $event[0].path" @add-files-main="content.languages[0].image = $event[0].path"/>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
        <teleport to="body">
            <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Content",
    data(){
        return{
            content: null,
            type: null,
            parent_types: [],
            body: "",
            submitStatus: "READY",
            schedulerState: 'publish',
            schedulerTime: null,
            schedulerDate: null,
            mediaOn: false,
            destination: null,
            toast_type: '',
            toast_message: '',
            toast_show: false,
        }
    },
    computed: {
        schedulerValue(){
            return (new Date(this.schedulerDate)).toLocaleDateString("en-CA", {
                format: "short",
                year: "2-digit",
                month: "2-digit",
                day: "2-digit"
            }) + " " + this.schedulerTime.hours + ":" + this.schedulerTime.minutes + ":00"
        }
    },
    async beforeMount(){
        try{
            if(this.$route.name.includes("edit")){
                this.content = await this.getContent(({params: "", id: this.$route.params.content_id}))
                this.$parent.$parent.content_name = this.content.languages[0].name
                if(this.content.type.parent && this.content.type.parent != 0){
                    let parent = this.content.type.parent;
                    let parent_types = []
                    while(parent != null && parent != 0){
                        let tmp = null;
                        try{
                            tmp = await this.getType(parent)
                        }catch{
                            //error but keep going
                        }
                        if(tmp != null){
                            parent_types.push(tmp)
                            parent = tmp.parent
                        }else{
                            parent = null
                        }
                    }
                    this.parent_types = parent_types.reverse()
                }
                    
            }else{
                let type = await this.getType(this.$route.query.id)
                this.type = type
                await this.buildContentTemplate(type);
                this.$parent.$parent.content_name = "Novo Conteúdo"
                if(type.parent && type.parent != 0){
                    let parent = type.parent;
                    let parent_types = []
                    while(parent != null && parent != 0){
                        let tmp = null;
                        try{
                            tmp = await this.getType(parent)
                        }catch{
                            //error but keep going
                        }
                        if(tmp != null){
                            parent_types.push(tmp)
                            parent = tmp.parent
                        }else{
                            parent = null
                        }
                    }
                    this.parent_types = parent_types.reverse()
                }
            }
            this.schedulerState = this.content.schedule_unpublished_at != null ? "unpublish" : "publish"
            this.$parent.$parent.transition = false;
        }catch(err){
            // this.$router.push({name:"erro"});
        }
    },
    methods: {
        ...mapActions(["getContent", "getType", "deleteBatchContents", "updateContent", "createContent", "togglePublished"]),
        async buildContentTemplate(type){
            let content = {
                languages: [{
                    alias: "",
                    image: "",
                    image_name: "",
                    alternative_image: "",
                    name: "",
                    body: "",
                    description: "",
                }],
                updated_at: (new Date()).toLocaleDateString("en-US", {
                    format: "short",
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }),
                user: {
                    name: this.$store.getters.auth.user.name
                },
                type: type
            }

            type.addons.forEach( t => {
                if(t == "attachment"){
                    content.attachments = []
                }

                if(t == "related"){
                    content.related = []
                }

                if(t == "categorie"){
                    content.categories = []
                }

                if(t == "field"){
                    content.fields = []
                }

                 if(t == "event"){
                    content.events = []
                }
            })
            this.content = content
        },
        addImage(type){
            this.destination = type;
            this.mediaOn = true;
        },
        removeImage(type){
            if(type == "main")
                this.content.languages[0].image = null
            else
                this.content.languages[0].alternative_image = null
        },
        openBar(element){
            document.querySelectorAll(".section.active").forEach( el => {if(!el.classList.contains(element)) el.classList.remove("active")})
            this.$refs[element].classList.toggle('active')
        },
        addFilesAttachments(data){
            let {files, category} = data
            
            let order = this.content.attachments.length+1;
            let tmpFiles=[]
            //create template_item
            files.forEach( f => {
                let tmp = {}
                tmp.file = f.path
                tmp.name = f.name
                tmp.orderattachment = order
                tmp.category_id = category
                tmp.id = Math.floor(Math.random() * Number.MAX_VALUE)
                if(f.description){
                    tmp.description = f.description
                }
                if(f.mime){
                    tmp.mimetype = f.mime
                    tmp.fileInfo = { size: f.size,dimensions:f.dimensions, user:f.user}
                }
                tmp.language_id = this.content.languages[0].language ? this.content.languages[0].language.id : 1
                tmpFiles.push(tmp)
                order++;
            })
            this.content.attachments = this.content.attachments.concat(tmpFiles)
        },
        removeFilesAttachments(id){
            let attachments = this.content.attachments
            if(attachments.length > id+1)
                //reorder every item after one position below
                for (let i = id+1; i < attachments.length; i++) {
                    attachments[i].orderattachment -=1;
                }

            //remove the desired item
            attachments.splice(id, 1)
            this.content.attachments = attachments
        },  
        async removeContent(){
            const ok = await this.$refs.modal.show({
                message: 'Deseja remover o conteúdo selecionado?',
                okButton: 'Remover conteúdo',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                await this.deleteBatchContents({contents: this.content.id});
                this.$router.push({ name: 'pages_cms', params: { id: this.$route.params.id }, query: { filter: this.content.type.id } })
            }
        },
        async togglePublish(){
            await this.togglePublished({published: (this.content.published == 1 ? false : true), id:this.content.id})
            this.content.published = this.content.published == 1 ? 0 : 1;
        },
        async saveContent(){
            try{
                //only base for now
                this.submitStatus = "PENDING"
                let languages = this.content.languages.map( l => {
                    let {alias, alternative_image, body, description, image, image_name, name} = l
                    let language_id = l.language ? l.language.id : 1
                    let created_at = this.content.created_at

                    return {alias, alternative_image, body, description, image, image_name, name, language_id, created_at}
                })

                let addons = {
                }

                if(this.content.attachments){
                    addons.attachment = this.content.attachments
                }

                if(this.$refs.addons.fields){
                    addons.field = []
                    this.$refs.addons.fields.forEach( f => {
                        if(f.value != null && f.value != '')
                            addons.field.push({
                                id: f.field_id,
                                value: f.value
                            })
                    })
                }

                if(this.$refs.addons.categories){
                    addons.category = []
                    this.$refs.addons.categories.forEach( c => {
                        if(c.value != null && c.value != false)
                            addons.category.push(c.category_id)
                    });
                }

                if(this.$refs.addons.events){
                    addons.event = this.$refs.addons.events;
                }

                if(this.content.relateds){
                    addons.related = this.content.relateds.map( r => { return r.content_related_id })
                }

                if(languages[0].name.trim() == '')
                    return

                let save = {
                    type_id: this.content.type.id,
                    languages: languages,
                    addons: addons
                }

                save['schedule_published_at'] = 'remove'
                save['schedule_unpublished_at'] = 'remove'

                if(this.schedulerDate != null && this.schedulerTime != null){
                    save['schedule_'+this.schedulerState+'ed_at'] = this.schedulerValue;
                }

                if(save['schedule_published_at'] != 'remove')
                    save.published = 0;
                else
                    save.published = this.content.published

                //set created_at
                save.created_at = this.content.created_at

                if(this.$route.name.includes("edit")){
                    await this.updateContent({params: save, id: this.content.id})
                }else{
                    await this.createContent(save)
                }

                this.$router.push({ name: 'pages_cms', params: { id: this.$route.params.id }, query: { filter: this.content.type.id } })
            }catch(err){
                this.submitStatus = "ERROR"
                this.toast_type = "error";
                this.toast_message = "Ocorreu um erro, ao guardar o conteúdo. Tente novamente!";
                this.toast_show = true;
            }
           
        }
    },
    mounted(){
        if(this.$route.name.includes("edit"))
            this.$parent.$parent.view = this.$t('edit')
        else
            this.$parent.$parent.view = this.$t('Criar')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/content.scss";   
</style>