import _ from "lodash";
import Api from "../api";

class Contents extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/contents";
        this.requiresAuth = true;
    }

    async getContents(params, result) {
        let url = this.uri + (params ? "?" + params : "");
        let response = await this.get(url, {}, this.requiresAuth);

        if (result !== undefined && result.data !== undefined) {
            result.data = result.data.concat(response.data)
        } else {
            result = response
        }

        if (params && params.includes("noorder") && response.nextPage !== null) {
            return this.getContents(response.nextPage.split('?')[1], result, true)
        }

        return result
    }

    getContent(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    searchContents(params, data) {
        let url = this.uri + "/search" + (params ? "?" + params : "");
        return this.post(url, data);
    }

    getDrafts(params, id) {
        let url = this.uri + "/" + id + "/drafts" + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    updateContent(params, id) {
        let url = this.uri + "/" + id;
        return this.put(url, params, this.requiresAuth);
    }

    createContent(params) {
        let url = this.uri;
        return this.post(url, params, this.requiresAuth);
    }

    deleteBatch(data) {
        let url = this.uri + "/delete/batch";
        return this.post(url, data);
    }

    batchContents(data, action) {
        let url = this.uri + "/batch/"+action;
        return this.post(url, data);
    }

    deleteDraft(id) {
        return this.delete(this.uri + "/drafts/" + id, {}, this.requiresAuth);
    }

    verifyBreakLink(id) {
        return this.get(this.uri + "/" + id + "/linkbreak", {}, this.requiresAuth);
    }


    togglePublished(published, id) {
        let data = {
            published: published
        }
        let url = this.uri + "/" + id;
        return this.put(url, data);
    }

    addonsAction(addon, action, content, value, field) {
        let data = {
            content_id: content,
            field_id: field,
            value: value
        }
        let url = "cms/v4/addons/" + addon + "/" + action;
        return this.post(url, data);
    }

    export (data) {
        return this.post(this.uri + "/export", data, {
            responseType: 'blob',
        });
    }

	cacheClear(url) {
        return this.get(url, {}, false);
    }
}

export default new Contents();