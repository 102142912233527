import Addons from "../../../libs/api/cms/addons";

export default {
    state: {
        addonsAttachmentsCategories: [],
        addonsAttachmentsCategoriesFlag: true
    },

    getters: {
        addonsAttachmentsCategories: state => state.addonsAttachmentsCategories,
        addonsAttachmentsCategoriesFlag: state => state.addonsAttachmentsCategoriesFlag,
    },

    mutations: {
        setAddonsAttachmentsCategories: (state, addonsAttachmentsCategories) => {
            state.addonsAttachmentsCategories = addonsAttachmentsCategories;
        },
        setAddonsAttachmentsCategoriesFlag: (state, addonsAttachmentsCategoriesFlag) => {
            state.addonsAttachmentsCategoriesFlag = addonsAttachmentsCategoriesFlag;
        }
    },

    actions: {
		getAddons: async ({ commit, dispatch }) => {
            const response = await Addons.getAddons();
            return response;
        },
        getAddonsAttachmentsCategories: async ({ commit, dispatch }) => {
            const response = await Addons.getAttachmentCategory();
            commit("setAddonsAttachmentsCategories", response);
            dispatch("setAddonsAttachmentsCategoriesFlag", false)

            return response;
        },
        createAttachmentCategory: async ({ commit, dispatch }, data) => {
            const response = await Addons.createAttachmentCategory(data);
            return response;
        },

        setAddonsAttachmentsCategoriesFlag: async ({ commit }, flag) => {
            commit("setAddonsAttachmentsCategoriesFlag", flag);
        },

        getAddonNutritionUnits: async ({ commit, dispatch }) => {
            const response = await Addons.getAddonNutritionUnits();
            return response;
        },
    }
};
