<template>
    <div :class="['context-menu', {'active': active}]" ref="el">
        <i :class="icon" @click="active = !active"></i>
        <div class="menu">
            <div v-for="(option,k) in options" :key="option.label" :disabled="option.disabled" @click="() => { option.func(); active = !active }">
                <i :class="option.class"></i>
                <label>{{option.label}}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ContextMenu",
    props:{
        options: {
            type: Array,
            default: []
        },
        icon: {
            type: String,
            default: 'i-options'
        }
    },
    data() {
        return{
            active: false
        }
    },
    created() {
        window.addEventListener("click", this.closeOnBlur)
    },
    destroyed() {
        window.removeEventListener("click", this.closeOnBlur);
    },
    methods: {
        closeOnBlur(event){
            if(this.active){
                if(!this.$refs.el.contains(event.target)){
                    this.active = false;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/context_menu.scss";
</style>