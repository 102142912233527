import { i18n } from '../../plugins/i18n'

export default function lang ({ next, store }) {
    if (store.state.language.language && store.state.language.language !== i18n.locale) {
        i18n.locale = store.state.language.language;
        return true
    } else if (!store.state.language.language) {
        store.dispatch('language/setLanguage', navigator.languages)
          .then(() => {
            i18n.locale = store.state.language.language;
            return true
          });
    } else {
        return true
    }
}