import io from "socket.io-client";
import store from '../../../store'

const state = {
    socket: null,
    licensed: localStorage.getItem("licensed") ? localStorage.getItem("licensed") : false,
    events: ["connect", "disconnect", "notification", "zelo-dashboard-activities", "worklog-refresh"],
}

const getters = {
    socket: (state) => state.socket,
    isLicensed: (state) => state.licensed,
}

const mutations = {
    setSocket(state, val) {
        state.socket = val;
    },
    setLicensed(state, val) {
        state.licensed = val;
        localStorage.setItem("licensed", val);
    },
}

const actions = {
    initSocket: async ({ commit, dispatch }, { node, namespace, token, identity }) => {
        // if (!state.socket) {
        //     const socket = await io(node, {
        //         reconnection: true,
        //         query: "domain=" + namespace + "&appToken=" + token + "&appType=backoffice&identity=" + identity + "&user=" + store.getters.auth.user.id,
        //     });
        //     commit("setSocket", socket);
        //     // bind all known events
        //     state.events.map((event) => {
        //         dispatch("bind", event);
        //     });
        // }
    },
    bind: async ({ commit, rootGetters }, event) => {
        state.socket.on(event, (data) => {
            switch (event) {
                case "worklog-refresh":
                    commit("setRefreshWorklogDashboard", new Date());
                    break;
                case "connected":
                case "connection": 
                    console.log("connected");
					break;
				case "zelo-dashboard-activities": 
					commit("refreshZeloActivities");
					break;
                case "notification":
                    data.date = new Date();
                    data.unread = true;
                    if(store.getters.auth.user && store.getters.auth.user.apps) {
                        let userApps = store.getters.auth.user.apps.reduce((a,c) => {
                            a.push(Number(c.app_id));
                            return a;
                        }, []);

                        let appTokens = store.getters.apps.reduce((a,c) => {
                            if(userApps.includes(Number(c.id))) {
                                a.push(c.token) 
                            }
                            return a;
                        }, []);

                        
                        let appToken = data.app || data.appToken;
                        if(appTokens.includes(appToken)) {
                            if(store.getters.auth.user.name !== data.user) {
                                commit("addUnreadNotification", data);
                            }
                        }
                    }
                    break;
            }
        });
    },
    disconnectSocket: async ({ commit }) => {
        if (state.socket) {
            await state.socket.disconnect();
            commit("setSocket", null);
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
};
