import Api from "./api";
import store from "./../../store";

class Filemanager extends Api {
    constructor() {
        super();
        this.uri = "filemanager";
        this.requiresAuth = true;
    }

    list(params) {
        return this.post(this.uri + "/list", params, this.requiresAuth);
    }

    getFiles(params) {
        return this.post(this.uri + "/files", params, this.requiresAuth);
    }

    getExtensions() {
        return this.get(this.uri + "/extensions", this.requiresAuth);
    }

    saveFiles(files, nr) {
        let count = 0;
        for (var i = 0; i < files.length; i = i+5) {
            const formData = new FormData(); // pass data as a form
            //send in bulk of 5
            for (var x = count; x < 5 + count; x++) {
                if(!files[x])
                    break;

                // append files as array to the form, feel free to change the array name
                formData.append("files["+x+"]", files[x]);
            }

            if(!process.env.VUE_APP_API_URL.includes("localhost"))
                formData.set('module', 'cms')
            else
                formData.set('module', 'local')
                
            formData.set('folder', '/uploads/cms')
            formData.set('app', store.getters.app)
            formData.set('nr', nr)
            formData.set('length', count+5)

            // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.
            this.post(this.uri + "/upload", formData, this.requiresAuth, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            count+=5
            nr++
        }
        return;
    }

    removeFiles(files){
        let params = {files:files}
        if(!process.env.VUE_APP_API_URL.includes("localhost"))
            params.module = 'cms'
        else
            params.module = 'local'
            
        params.folder = '/uploads/cms'
        params.app = store.getters.app
        return this.post(this.uri + "/delete", params, this.requiresAuth);
    }

    editFiles(files){
        return this.put(this.uri + "/edit", files, this.requiresAuth);
    }
}

export default new Filemanager();
