export default {
    email: "E-mail",
    email_placeholder: "Insert registered e-mail",
    email_placeholder_2: "Insert registered e-mail",
    password: "Password",
    remember_login_access: "Remember email",
    login: "Login",
    enter: "Enter",
    password_update_title: "Update password",
    password_update_description: "For security reasons, we suggest you to change your password.",
    password_update_bt: "Change password",
    login_success: "Authentication validated",
    forgot_password: "Forgot password",
    error_1: "Incorrect information. Please, try again.",
    error_2: "Wrong code. Please, try again.",
    error_3: "Wrong email. Insert a valid email.",
    auth_code_title: "Authentication Code",
    auth_code_description: "To continue we need you to verify your email and insert your authentication code.",
    timer_label: "Your code",
    validate_code: "Validate code",
    resend_code: "Resend code",
    cancel: "Cancel",
    expires_in: 'expires in ',
    expired: 'expired',
    go_back: 'Go back',
    recover_password: 'Recover password',
    recover_password_description: 'We will send you an email with all the instructions to recover your password.',
    email_sent: "Email sent",
    email_sent_description: "Verify your email with all the needed information to change your password.",
    dashboard: "Dashboard",
    apps: "Apps",
    notifications: "Notifications",
    notifications_empty: "No new notifications",
    remove_all: "Remove all",
    users: "Users",
    users_empty: "No users",
    view_all: "View all",
    devices: "Devices",
    devices_empty: "No associated devices to this account",
    no_app: "No application",
    stats: "Stats",
    environments: "Environments",
    quick_access: "Quick Access",
    pages: "Pages",
    news: "News",
    sliders: "Sliders",
    menus: "Menus",
    media: "Media",
    forms: "Forms",
    change_env: "Change environment",
    stats_empty: "No stats to report",
    environment_empty: "No aditional environments",
    error_4: "Passwords match",
    error_5: "Passwords don't match",
    error_6: 'Incorrect data. Try again.',
    error_7: 'Incorrect code. Try again.',
    error_8: 'The given token was not found',
    error_9: 'Password already used. Choose a new password.',
    name: "Name",
    location: "Location",
    date_created: "Date created",
    date_updated: "Date updated",
    asc: "Ascending",
    desc: "Descending",
    published: "Published",
    unpublished: "Unpublished",
    draft: "Draft",
    add_el: "Add element",
    export_table: "Export table",
    clear_cache: "Clear cache",
    disable_el: "Disable element",
    delete_el: "Delete element",
    filter_by: "Filter by",
    state: "State",
    restore: "Restore",
    apply_filters: "Apply filters",
    order_by: "Order by",
    order_type: "Order type",
    order: "Order",
    fast_search: "Quick search",
    entries: "Entries",
    results: "results",
    update: "Updated",
    creation: "Created",
    edit: "Edit",
    remove: "Remove",
    add: "Add",
    edited: "Edited",
    options: "Options",
    filter: "Filter",
    version: "Version",
    go_to: "Go to",
    Esconder: "Hide",
    Template: "Template",
    Posição: "Position",
    Plugin: "Plugin",
    up_level: "Go up a level",
    down_level: "Go down a level",
    templates: "Templates",
    types: "Types",
    type: "Type",
    fields: "Fields"
};
