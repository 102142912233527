<template>
	<div :class="[{'error': v$.value.$error }, {'disabled': disabled}, {'icon-box': icon_box }, {'full': full }]" class="fyi-input email">
		<label>{{label}}</label>
		<input
			type="email"
			name="email"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
			v-model="value"
			@input="$emit('input-email', value); $emit('email-valid', !(v$.value.$error));"
			@keyup.enter="$emit('submit')"
		/>
		<label v-if="inputError" class="error-label">{{ errorMessage }}</label>
		<i v-if="inputError" class="error-icon fas fa-exclamation-circle"></i>
	</div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
	name: "fyi-email",
	setup () { return { v$: useValidate() } },
	watch: {
		value(_new, _old){
			this.v$.$validate();
		}
	},
	props: {
		value: String,
		alias: String,
		label: {
			type: String,
			default: ""
		},
		errorMessage: {
			type: String,
			default: "Introduza um e-mail válido"
		},
		placeholder: {
			type: String,
			default: ""
		},
		icon_box: {
            type: Boolean,
            default: true
        },
		full: {
            type: Boolean,
            default: true
        },
		disabled: {
			type: Boolean,
			default: false
		},
		validate: {
			type: Boolean,
			default: true
		},
		required: {
			type: Boolean,
			default: false
		},
		inputError: {
			type: Boolean,
			default: true
		}
	},	
	  validations () {
		return {
			value: {
				email,
				required
			}
		}
	},
	mounted(){
		if(this.value !== ""){
			this.v$.$validate();
			this.$emit('email-valid', !(this.v$.value.$error))
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/input";
</style>